import React from 'react';
import { BoatIcon } from '../../assets';
import styles from './OpeningAddinPage.module.scss';

const FetchingArchivedPage = () => (
  <div className={styles.container}>
    <BoatIcon />
    <h1>Fetching archived document</h1>
  </div>
);

export default FetchingArchivedPage;
