import PropTypes from 'prop-types';
import { maybeShape } from '../lib';

const Maybe = ({ type, nothing, just }) =>
  type.cata(
    nothing || (() => null),
    just
  );

Maybe.propTypes = {
  type: PropTypes.shape(maybeShape).isRequired,
  just: PropTypes.func.isRequired
};

export default Maybe;
