import React from 'react';
import PropTypes from 'prop-types';
import { Switch, Route, MemoryRouter } from 'react-router-dom';
import DocumentStatusPage from '../containers/DocumentStatusPage/index';
import ArchivedPage from '../containers/ArchivedPage/index';
import SearchPage from '../containers/SearchPage/index';
import DocumentPage from '../containers/DocumentPage/index';
import {
  ErrorPage, NotFoundPage, ProtectedRoute,
} from '../components';

export const routes = {
  root: '/',
  new: '/new',
  edit: '/edit',
  archived: '/archived',
  archivedEdited: '/archived/:hasEdited',
  search: '/search',
  error: '/error'
};

const Router = ({ ...props }) => (
  <MemoryRouter>
    {props.children}
    <Switch>
      <Route exact path={routes.error} component={ErrorPage} />
      <ProtectedRoute exact path={routes.root} component={DocumentStatusPage} />
      <ProtectedRoute exact path={routes.new} component={DocumentPage} />
      <ProtectedRoute exact path={routes.edit} component={DocumentPage} />
      <ProtectedRoute exact path={[routes.archived, routes.archivedEdited]} component={ArchivedPage} />
      <ProtectedRoute exact path={routes.search} component={SearchPage} />
      <Route exact component={NotFoundPage} />
    </Switch>
  </MemoryRouter>
);

Router.propTypes = {
  children: PropTypes.object.isRequired,
};

export default Router;
