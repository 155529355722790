import React from 'react';
import PropTypes from 'prop-types';

import { ErrorPage, LookingForContextPage, RemoteData } from '../../components';
import { remoteDataShape } from '../../lib';

const AnalyzingWrapper = ({ children, documentAnalysis, retry }) => (
  <RemoteData type={documentAnalysis}
              notAsked={LookingForContextPage}
              loading={LookingForContextPage}
              failure={err => <ErrorPage message={err} retryAction={retry} />}
              success={analysis => children(analysis)} />
);

AnalyzingWrapper.propTypes = {
  documentAnalysis: PropTypes.shape(remoteDataShape).isRequired,
  children: PropTypes.func.isRequired,
  retry: PropTypes.func.isRequired,
};

export default AnalyzingWrapper;
