import { Nothing } from 'monet';
import { pipe, filter, startsWith } from 'ramda';
import { toMaybe } from '../../../lib';

const toLower = str =>
  str && typeof str === 'string'
    ? str.toLowerCase()
    : '';

const searchKeywords = (keywords, docType, searchString) => {
  if (docType.disabled) {
    return Nothing();
  }

  const input = toLower(searchString);

  return pipe(
    filter(keyword => toLower(keyword.docType) === toLower(docType.value)),
    filter(keyword =>
      input.length > 0
        ? startsWith(toLower(input), toLower(keyword.value))
        : true),
    toMaybe
  )(keywords);
};

export default searchKeywords;
