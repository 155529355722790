import { toMaybe } from '../../lib';
import { inSharedMailBox, getSharedMailBoxOwner } from '../office';

const missingMailbox = new Error('MISSING MAILBOX');

const mapMailbox = (Office, email) => new Promise(
  (resolve, reject) =>
    // eslint-disable-next-line no-promise-executor-return
    inSharedMailBox(Office)
      ? getSharedMailBoxOwner(Office)
        .then(resolve)
        .catch(_ => reject(missingMailbox))
      : toMaybe(email).cata(
        () => reject(missingMailbox),
        resolve,
      ),
);

export default mapMailbox;
