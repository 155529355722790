import { decodeJwt } from 'jose';
import { eitherTry } from '../lib';

const { sessionStorage } = window;

const getItsTokenFromSession = () => {
  const token = sessionStorage.getItem('itsToken');
  return (token === undefined || token === null)
    ? Promise.reject(new Error('No token'))
    : Promise.resolve(token);
};

const isTokenInValid = (token: string | null, offsetMinutes: number) => {
  if (token === undefined || token === null) {
    return true;
  }

  const { exp } = decodeJwt(token);
  const expiry = new Date(0);
  expiry.setUTCSeconds(exp || 0);
  const now = new Date();
  if (offsetMinutes) {
    now.setMinutes(now.getMinutes() + offsetMinutes);
  }

  return now > expiry;
};

const isItsTokenMissingOrExpired = () => isTokenInValid(sessionStorage.getItem('itsToken'), 5);

const storeItsToken =
    (token: string) =>
      eitherTry(
        () => sessionStorage.setItem('itsToken', token),
      );

export default {
  getItsTokenFromSession,
  isItsTokenMissingOrExpired,
  storeItsToken,
};
