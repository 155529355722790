import React from 'react';
import { OpeningDrawerIcon } from '../../assets';
import styles from './OpeningAddinPage.module.scss';

const OpeningAddinPage = () => (
  <div className={styles.container}>
    <OpeningDrawerIcon />
    <h1>Opening archive drawer</h1>
  </div>
);

export default OpeningAddinPage;
