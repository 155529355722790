import { map, head, curry } from 'ramda';
import { handleActions } from 'redux-actions';
import { remoteDataToMaybe, RemoteData } from '../../lib';
import * as a from './constants';

const unknownRole = {
  displayName: 'Unknown'
};

export const defaultState = {
  remoteRoles: RemoteData.NotAsked,
  activeRole: unknownRole,
  user: RemoteData.NotAsked
};

export const updateSelectedRole = curry(
  (newRole, roles) =>
    map(role => ({
      ...role,
      selected: role.value === newRole.value
    }), roles)
);

// NOTE: No default roles in model yet, use first as default
const updateActiveRoleWhenUserHasRoles = (activeRole, roles) =>
  head(roles) || activeRole;

const gotUserRoles = remoteUser =>
  remoteDataToMaybe(remoteUser)
    .cata(
      () => false,
      user => user.roles.length > 0
    );

// NOTE: If user roles fetched before all roles, and user has any roles, use that
export const updateActiveRole = (state, roles) =>
  gotUserRoles(state.user)
    ? state.activeRole
    : (head(roles) || unknownRole);

const reducer = handleActions({
  [a.FETCH_ROLES]: state => ({
    ...state, remoteRoles: RemoteData.Loading
  }),

  [a.FETCH_ROLES_ERROR]: (state, action) => ({
    ...state, remoteRoles: RemoteData.Failure(action.payload)
  }),

  [a.FETCH_ROLES_SUCCESS]: (state, action) => {
    const activeRole = updateActiveRole(state, action.payload);
    const roles = updateSelectedRole(activeRole, action.payload);

    return {
      ...state,
      remoteRoles: RemoteData.Success(roles),
      activeRole
    };
  },

  [a.FETCH_USER]: state => ({
    ...state, user: RemoteData.Loading
  }),

  [a.FETCH_USER_ERROR]: (state, action) => ({
    ...state, user: RemoteData.Failure(action.payload)
  }),

  [a.FETCH_USER_SUCCESS]: (state, action) => {
    const activeRole = updateActiveRoleWhenUserHasRoles(state.activeRole, action.payload.roles);
    return {

      ...state,
      user: RemoteData.Success(action.payload),
      remoteRoles: state.remoteRoles.map(updateSelectedRole(activeRole)),
      activeRole
    };
  },

  [a.ROLE_CHANGED]: (state, action) => ({
    ...state,
    remoteRoles: state.remoteRoles.map(updateSelectedRole(action.payload)),
    activeRole: action.payload
  })

}, defaultState);

export default reducer;
