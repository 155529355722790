import React from 'react';
import styles from './NotEmailPage.module.scss';
import { CreateEventIcon } from '../../assets';

const NotEmailPage = () => (
  <div className={styles.container}>
    <CreateEventIcon />
    <h1>Not supported</h1>
    <h4>Selected email is not an archivable document; please select another email.</h4>
  </div>
);

export default NotEmailPage;
