import { connect } from 'react-redux';
import React from 'react';

const withInit = (initFn, Component) =>
  class extends React.Component {
    componentDidMount() {
      initFn(this.props);
    }

    render() {
      return <Component {...this.props} />;
    }
  };

const connectWithInit = (init, mapStateToProps, mapDispatchToProps, mergeProps) =>
  Component =>
    connect(mapStateToProps, mapDispatchToProps, mergeProps)(withInit(init, Component));

export default connectWithInit;
