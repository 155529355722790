import { reduce, find, keys } from 'ramda';

const mapField = (field, errors) => {
  const error = find(err => err.path === field, errors);

  return {
    valid: !error,
    message: (error && error.message) || ''
  };
};

const mapFields = (model, errors) =>
  reduce((validationResult, field) => ({
    ...validationResult, [field]: mapField(field, errors)
  }), {}, keys(model));

const mapValidationResult = (model, errors, valid) => ({
  valid,
  fields: mapFields(model, errors)
});

// NOTE: validateSync throws when validation errors. So no errors = valid
const validate = (schema, model, context) => {
  try {
    schema.validateSync(model, { abortEarly: false, context });

    return mapValidationResult(model, [], true);
  } catch (err) {
    const validationResult = mapValidationResult(model, err.inner, false);
    return validationResult;
  }
};

export default validate;
