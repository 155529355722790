import {
  trim, split, toLower, join, pipe, curry
} from 'ramda';

const trimToLowerStr =
  pipe(trim, split(' '), join(''), toLower);

const stringTrimToLowerMatches = curry(
  (str, toMatch) =>
    pipe(
      trimToLowerStr,
      formattedStr =>
        formattedStr === trimToLowerStr(toMatch)
    )(str)
);

export default stringTrimToLowerMatches;
