const getDocumentType = role => {
  switch (role.toLowerCase()) {
    case 'underwriter':
    case 'underwriterpi':
    case 'underwriterenergy':
    case 'businessoperations': return 'Renewal / New Business';
    case 'reinsurance': return 'Placing';
    default: return 'Correspondence';
  }
};

const getDefaultDocumentType = role => ({
  displayName: getDocumentType(role),
  value: getDocumentType(role),
});

export default getDefaultDocumentType;
