import { Nothing } from 'monet';
import { toMaybe, remoteDataToMaybe } from '../../../lib';

const defaultAnalysis = {
  contextList: Nothing(),
  taxonomy: Nothing(),
  classification: Nothing(),
  receivedAt: Nothing(),
  note: ''
};

const getArchivedDocumentAnalysis = remoteArchived =>
  remoteDataToMaybe(remoteArchived)
    .cata(
      () => defaultAnalysis,
      archivedDocument => ({
        contextList: archivedDocument.contextList,
        taxonomy: archivedDocument.content.taxonomy,
        classification: toMaybe(archivedDocument.content.classification),
        receivedAt: archivedDocument.content.receivedAt,
        note: archivedDocument.content.notes
      })
    );

export default getArchivedDocumentAnalysis;
