import { all } from 'ramda';

/* -appointmentProps-
* A list of properties set only when an item is an appointment
* May change... */
const appointmentProps = [
  'recurrence',
  // 'optionalAttendees',
  // 'requiredAttendees',
  'start',
  'end',
  'location',
  // 'enhancedLocation',
  'seriesId',
];
/* This method needs attention:
* Office.context.mailbox.item has the string property 'itemType' which is always "message" while
* initialType of eventArgs from event ItemChanged hae an integer property 'itemType'.
* initialType.itemType is 1 for messages and is therefore uses in the event.
* string Office.context.mailbox.itemClass changes though and has value "IPM.Note" for messages.
* Most likely mailbox.itemType in item should align with initialType.itemType
*/
const isItemEmail = item => {
  if (!item) {
    return false;
  }
  const isAppointment =
    all(
      prop =>
        Object.prototype.hasOwnProperty.call(item, prop) &&
      item[prop] !== undefined,
      appointmentProps
    );

  return !isAppointment;
};

export default isItemEmail;
