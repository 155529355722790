import React, { useRef, useEffect } from 'react';
import cn from 'classnames';
import PropTypes from 'prop-types';
import { mdiRadioboxBlank, mdiRadioboxMarked } from '@mdi/js';
import uuid from 'uuid/v4';
import { map } from 'ramda';
import styles from './Dropdown.module.scss';
import Icon from './Icon';
import vars from '../scss/variables.scss';

const getCheckBox = selected =>
  selected
    ? <Icon className={styles.checkbox} path={mdiRadioboxMarked} size={vars.iconSizeMedium} />
    : (
      <Icon className={cn(styles.checkbox, styles.unchecked)}
            path={mdiRadioboxBlank}
            size={vars.iconSizeMedium} />
    );

const mapOptions = (values, onSelect, inactive) =>
  map(opt => {
    const clickHandler = inactive ? () => null : () => onSelect(opt);

    return (
      <li key={opt.value} className={styles.item} role="button" onClick={clickHandler}>
        {getCheckBox(opt.selected)}
        <span>{opt.displayName}</span>
      </li>
    );
  }, values);

const viewInactiveMessage = (inactive, message) =>
  inactive && <li className={styles.warning}>{message}</li>;

const getContainerClassNames = show =>
  cn(styles.container, {
    [styles.visible]: show === true,
  });

const DropdownField = ({
  values, onSelect, toggleShow, show, inactive = false, inactiveMessage = '',
}) => {
  const listRef = useRef(uuid());

  const clickOutsideHandler = evt => {
    if (show && !listRef.current.contains(evt.target)) {
      toggleShow(false);
    }
  };

  useEffect(() => {
    window.addEventListener('click', clickOutsideHandler);

    return () => {
      window.removeEventListener('click', clickOutsideHandler);
    };
  });

  const selectValue = value => {
    onSelect(value);
    toggleShow(!show);
  };

  return (
    <ul ref={listRef} className={getContainerClassNames(show)}>
      {viewInactiveMessage(inactive, inactiveMessage)}
      {mapOptions(values, selectValue, inactive)}
    </ul>
  );
};

const optionShape = {
  value: PropTypes.string,
  displayName: PropTypes.string,
  selected: PropTypes.bool,
};

DropdownField.propTypes = {
  values: PropTypes.arrayOf(PropTypes.shape(optionShape)).isRequired,
  onSelect: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  toggleShow: PropTypes.func.isRequired,
  inactive: PropTypes.bool,
  inactiveMessage: PropTypes.string,
};

export default DropdownField;
