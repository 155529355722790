import { reduce } from 'ramda';

export const fst = ([first, _]) =>
  first;

export const snd = ([_, second]) =>
  second;

// NOTE: Tuples can be really useful, pretend we have them.
// fst and snd are helpers for extracting tuple values
// Pair<string, string | number | bool>
// buildQueryString takes a List<Pair> and returns a string. Ex: [ ["userId", "1"] ] -> ?userId=1
const buildQueryString =
    reduce((acc, pair) =>
      acc === ''
        ? `?${fst(pair)}=${snd(pair)}`
        : `${acc}&${fst(pair)}=${snd(pair)}`, '');

export default buildQueryString;
