import {
  AuthenticationResult,
  Configuration,
  EventMessage,
  EventType,
  InteractionRequiredAuthError,
  LogLevel,
  PublicClientApplication
} from '@azure/msal-browser';
import tokenType from '../lib/constants';
import { getAppInsights } from '../lib';
import getMetaValue from '../lib/getMetaValue';
import fetchItsToken from './itsToken';
import stores from './storage';
import { getUserEmail } from '../lib/officeHelpers';

declare global {
  // eslint-disable-next-line no-unused-vars
  interface Window {
      Office: any;
  }
}

const appInsights = getAppInsights();
const clientId = getMetaValue(document, 'clientId');
const authority = `https://login.microsoftonline.com/${getMetaValue(document, 'tenantId')}/`;
const port = window.location.port ? `:${window.location.port}` : '';
const baseUri = `${window.location.protocol}//${window.location.hostname}${port}/`;

export const scopes = [
  'Mail.Readwrite',
  'Mail.Readwrite.Shared',
  'Mailboxsettings.ReadWrite',
  'openid',
  'profile',
  'User.Read',
  'User.Readbasic.All',
  'email'
];

const loggerCallback = (level: LogLevel, message: string, containsPii: boolean) => {
  if (containsPii) {
    return;
  }
  switch (level) {
    case LogLevel.Error:
      appInsights.trackException({ exception: new Error(message) });
      break;
    case LogLevel.Info:
      appInsights.trackTrace({ message });
      break;
    case LogLevel.Verbose:
      break;
    case LogLevel.Warning:
      appInsights.trackTrace({ message, severityLevel: 2 });
      break;
    default:
      break;
  }
};

const msalConfig: Configuration = {
  auth: {
    authority,
    clientId,
    navigateToLoginRequestUrl: false,
    postLogoutRedirectUri: baseUri,
    redirectUri: baseUri
  },
  system: {
    loadFrameTimeout: 10000,
    loggerOptions: {
      loggerCallback,
      piiLoggingEnabled: false,
      logLevel: LogLevel.Verbose,
      correlationId: '1234'
    },
  },
  cache: {
    cacheLocation: 'localStorage', // must(!) be localStorage to provide the users Single Sign On accross emails and user sessions
    storeAuthStateInCookie: true,
  },
};

let publicClientApplication: PublicClientApplication | null = null;

const createPublicClientApplication = () => {
  publicClientApplication = new PublicClientApplication(msalConfig);

  publicClientApplication.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS) {
      const paylaod = event.payload as AuthenticationResult;
      publicClientApplication!.setActiveAccount(paylaod.account);
    }
  });

  return publicClientApplication;
};

const updateItsToken = (authResponse: AuthenticationResult) => {
  const { accessToken, fromCache } = authResponse;
  const shouldRenewToken = stores.isItsTokenMissingOrExpired() || fromCache === false;

  if (shouldRenewToken) {
    return fetchItsToken(accessToken, tokenType.AAD)
      .then(() => Promise.resolve(accessToken));
  }
  return Promise.resolve(accessToken);
};

export const getAadToken = () => {
  if (!publicClientApplication) {
    return Promise.reject(new Error('publicClientApplication not initialized'));
  }

  const emailAddress = getUserEmail();
  const currentAccount = publicClientApplication.getAccountByUsername(emailAddress);
  const silentRequest = {
    scopes,
    account: currentAccount ?? undefined,
    forceRefresh: false
  };
  const popupRequest = {
    scopes,
    loginHint: emailAddress
  };

  const accessToken = publicClientApplication.acquireTokenSilent(silentRequest)
    .catch(error => {
      if (error instanceof InteractionRequiredAuthError) {
        return publicClientApplication!.acquireTokenPopup(popupRequest);
      }
      return Promise.reject(error);
    })
    .then(updateItsToken);

  return accessToken;
};

export const getItsToken = () =>
  getAadToken()
    .then(() => stores.getItsTokenFromSession())
    .catch(Promise.reject);

export default createPublicClientApplication;
