import React from 'react';
import { Circle } from 'its-react-ui';
import styles from './Unarchive.module.scss';

const Archiving = () => (
  <>
    <div className={styles.icon}><Circle /></div>
    <h4 className="bold">Please wait ...</h4>
    <h4>Removing the document.</h4>
  </>
);

export default Archiving;
