import PropTypes from 'prop-types';
import React from 'react';
import { DCheckIcon } from '../../../assets';
import styles from './Unarchive.module.scss';

const Success = ({ onClose }) => (
  <>
    <DCheckIcon className={styles.icon} />
    <h4 className="bold">Successfully unarchived</h4>
    <h4>You can archive the document again at any time.</h4>
    <div className={styles.buttonFlex}>
      <button className={styles.close}
              type="button"
              onClick={onClose}>
        <span>Close</span>
      </button>
    </div>
  </>
);

Success.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Success;
