export const ADD_KEYWORD = 'document/ADD_KEYWORD';
export const ADD_NOTE = 'document/ADD_NOTE';
export const ARCHIVE_DOCUMENT = 'document/ARCHIVE_DOCUMENT';
export const ARCHIVE_DOCUMENT_CANCEL = 'document/ARCHIVE_DOCUMENT_CANCEL';
export const ARCHIVE_DOCUMENT_ERROR = 'document/ARCHIVE_DOCUMENT_ERROR';
export const ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS = 'document/ARCHIVE_DOCUMENT_EXCLUDING_INVALID_ATTACHMENTS';
export const ARCHIVE_DOCUMENT_SUCCESS = 'document/ARCHIVE_DOCUMENT_SUCCESS';
export const CLASSIFICATIONS_CHANGED = 'document/CLASSIFICATIONS_CHANGED';
export const DELETE_KEYWORD = 'document/DELETE_KEYWORD';
export const DOCUMENT_TYPES_CHANGED = 'document/DOCUMENT_TYPES_CHANGED';
export const FETCH_ANALYSIS = 'document/FETCH_ANALYSIS';
export const FETCH_ANALYSIS_ERROR = 'document/FETCH_ANALYSIS_ERROR';
export const FETCH_ANALYSIS_SUCCESS = 'document/FETCH_ANALYSIS_SUCCESS';
export const FETCH_CLASSIFICATIONS_SUCCESS = 'document/FETCH_CLASSIFICATIONS_SUCCESS';
export const FETCH_DOCUMENT_TYPES_SUCCESS = 'document/FETCH_DOCUMENT_TYPES_SUCCESS';
export const FETCH_EDIT_DOCUMENT = 'document/FETCH_EDIT_DOCUMENT';
export const FETCH_EDIT_DOCUMENT_ERROR = 'document/FETCH_EDIT_DOCUMENT_ERROR';
export const FETCH_EDIT_DOCUMENT_SUCCESS = 'document/FETCH_EDIT_DOCUMENT_SUCCESS';
export const FETCH_SUGGESTIONS = 'document/FETCH_SUGGESTIONS';
export const FETCH_SUGGESTIONS_ERROR = 'document/FETCH_SUGGESTIONS_ERROR';
export const FETCH_SUGGESTIONS_SUCCESS = 'document/FETCH_SUGGESTIONS_SUCCESS';
export const FETCH_UPDATE_TAXONOMY_ERROR = 'document/FETCH_UPDATE_TAXONOMY_ERROR';
export const FETCH_UPDATE_TAXONOMY_SUCCESS = 'document/FETCH_UPDATE_TAXONOMY_SUCCESS';
export const IS_PII_CHECKED_CHANGED = 'document/IS_PII_CHECKED_CHANGED';
export const PII_SECTION_CHANGED = 'document/PII_SECTION_CHANGED';
export const RESET_DOCUMENT_PAGE = 'document/RESET_DOCUMENT_PAGE';
export const SEARCH_STRING_CHANGED = 'document/SEARCH_STRING_CHANGED';
export const SHOW_INVALID_ATTACHMENTS_RESULT = 'document/SHOW_INVALID_ATTACHMENTS_RESULT';
export const SHOW_VALIDATION_RESULT = 'document/SHOW_VALIDATION_RESULT';
export const TOGGLE_ADD_KEYWORDS = 'document/TOGGLE_ADD_KEYWORDS';
export const TOGGLE_CONTEXT_LISTING = 'document/TOGGLE_CONTEXT_LISTING';
export const UPDATE_ANALYSIS_CONTEXT_LIST = 'document/UPDATE_ANALYSIS_CONTEXT_LIST';
export const UPDATE_DOCUMENT = 'document/UPDATE_DOCUMENT';
export const UPDATE_DOCUMENT_ERROR = 'document/UPDATE_DOCUMENT_ERROR';
export const UPDATE_DOCUMENT_SUCCESS = 'document/UPDATE_DOCUMENT_SUCCESS';
export const VALIDATE_ATTACHMENTS = 'document/VALIDATE_ATTACHMENTS';
export const WATCH_ANALYSE_DOCUMENT = 'containers/documentPage/WATCH_ANALYSE_DOCUMENT';
export const WATCH_FETCH_EDIT_DOCUMENT = 'containers/documentPage/WATCH_FETCH_EDIT_DOCUMENT';
export const WATCH_UPDATE_DOCUMENT = 'containers/documentPage/WATCH_UPDATE_DOCUMENT';
export const WATCH_UPDATE_TAXONOMY = 'containers/documentPage/WATCH_UPDATE_TAXONOMY';
export const WATCH_VALIDATE_ATTACHMENTS = 'containers/documentPage/WATCH_VALIDATE_ATTACHMENTS';
