import { Just } from 'monet';

const defaultClassification = 'Client Info';

const mapClassification = item => {
  const classification = item && item.classification;

  return classification
    ? Just(classification)
    : Just(defaultClassification);
};

export default mapClassification;
