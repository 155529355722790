import PropTypes from 'prop-types';

export const tagShape = {
  displayName: PropTypes.string,
  docType: PropTypes.string,
  value: PropTypes.string,
};

export const remoteDataShape = {
  'data': PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  'is': PropTypes.func,
  '@@values': PropTypes.array,
};

const isDefined = (props, propName, componentName) => {
  if (!Object.prototype.hasOwnProperty.call(props, propName)) {
    return new Error(`A maybe has to have a val prop: ${propName} - ${componentName}`);
  }
  return null;
};

export const maybeShape = {
  '@@type': PropTypes.string.isRequired,
  'isValue': PropTypes.bool.isRequired,
  'val': isDefined
};

export const archivedContentShape = {
  taxonomy: PropTypes.shape(maybeShape).isRequired,
  receivedAt: PropTypes.shape(maybeShape).isRequired,
  classification: PropTypes.string.isRequired
};

export const roleShape = {
  role: PropTypes.string.isRequired,
  displayName: PropTypes.string.isRequired
};

export const userShape = {
  role: PropTypes.shape({
    value: PropTypes.string.isRequired,
    displayName: PropTypes.string.isRequired
  }).isRequired,
  mailbox: PropTypes.string.isRequired,
  userMailbox: PropTypes.string.isRequired,
};

export const validationShape = {
  showValidationResult: PropTypes.bool.isRequired,
  valid: PropTypes.bool.isRequired,
  fields: PropTypes.object.isRequired
};

export const documentModelShape = {
  documentType: PropTypes.string.isRequired,
  classification: PropTypes.string.isRequired,
  note: PropTypes.string.isRequired,
  keywords: PropTypes.array.isRequired,
  contexts: PropTypes.array.isRequired,
  piiSection: PropTypes.string
};

export const selectOptionShape = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  displayName: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  selected: PropTypes.bool,
};
