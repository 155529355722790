import {
  map,
  reduce,
  flatten,
  pipe
} from 'ramda';

const mapDocTypes =
  map(({
    id, name, displayName, disabled
  }) => ({
    id,
    value: name,
    displayName,
    disabled,
    selected: false
  }));

const mapDocType =
  map(docType =>
    reduce((acc, val) => [
      ...acc, {
        docType: docType.name,
        value: val.name,
        displayName: val.displayName
      }
    ], [], docType.tags));

const mapTags =
  pipe(mapDocType, flatten);

const mapDocumentTypes = documentTypes => ({
  documentTypes: mapDocTypes(documentTypes),
  tags: mapTags(documentTypes)
});

export default mapDocumentTypes;
