import React from 'react';
import PropTypes from 'prop-types';
import { map, addIndex } from 'ramda';
import {
  mdiFileDocumentBoxOutline, mdiStarOutline, mdiClockOutline, mdiTagTextOutline, mdiAccountAlert
} from '@mdi/js';
import { ContentRow } from 'its-react-ui';
import {
  Maybe,
  ReceivedAt,
} from '../../../components';
import { Icon } from '../../../ui';
import styles from './archivedContent/ArchivedContent.module.scss';
import NoteRow from '../../../components/common/NoteRow';
import { maybeShape, archivedContentShape } from '../../../lib';
import vars from '../../../scss/variables.scss';

const indexedMap = addIndex(map);

const mapArchivedKeyWords =
  indexedMap((keyword, i) => (
    <li key={i} className={styles.keywordStyle}>{keyword.displayName}</li>
  ));

const ArchivedDocType = ({ taxonomy: maybeTaxonomy }) => (
  <Maybe type={maybeTaxonomy}
         nothing={() => <span>Unable to find archived document type</span>}
         just={taxonomy => (
           <span className={styles.capitalize}>{taxonomy.documentType.displayName}</span>
         )} />
);

ArchivedDocType.propTypes = {
  taxonomy: PropTypes.shape(maybeShape).isRequired
};

const ArchivedKeywords = ({ taxonomy: maybeTaxonomy }) => (
  <Maybe type={maybeTaxonomy}
         nothing={() => <span>No keywords archived</span>}
         just={taxonomy => (
           <ul className={styles.keywordsList}>
             {mapArchivedKeyWords(taxonomy.documentTags.sort((first, second) =>
               first.displayName.toUpperCase() < second.displayName.toUpperCase() ? -1 : 1))}
           </ul>
         )} />
);

ArchivedKeywords.propTypes = {
  taxonomy: PropTypes.shape(maybeShape).isRequired
};

const ArchivedContent = ({ content }) => (
  <div className={styles.archivedContentContainer}>
    <ContentRow archivedView icon={mdiFileDocumentBoxOutline} title="Doc. Type">
      <ArchivedDocType taxonomy={content.taxonomy} />
    </ContentRow>
    <ContentRow archivedView icon={mdiStarOutline} title="Classification">{content.classification}</ContentRow>
    <ContentRow archivedView icon={mdiClockOutline} title="Time / Date">
      <ReceivedAt maybeDate={content.receivedAt} nothing={() => <span>No archived date found</span>} />
    </ContentRow>
    <ContentRow archivedView icon={mdiAccountAlert} title="PII">{content.piiSectionText}</ContentRow>
    <NoteRow archivedView note={content.notes} />
    <div className={styles.ieWrapper}>
      <div className={styles.contentRowOverride}>
        <div className={styles.iconAndTitle}>
          <Icon path={mdiTagTextOutline} size={vars.iconSizeSmall} />
          <span>Keywords</span>
        </div>
      </div>
      <div>
        <ArchivedKeywords taxonomy={content.taxonomy} />
      </div>
    </div>
  </div>
);

ArchivedContent.propTypes = {
  content: PropTypes.shape(archivedContentShape).isRequired
};

export default ArchivedContent;
