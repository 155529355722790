import { map, prop } from 'ramda';
import { valueOrThrow, toMaybe, eitherTry } from '../../lib';

const mapTaxonomy = ({ documentType, keywords, applicationRoleName }) => ({
  documentTags: map(prop('value'), keywords),
  documentType: valueOrThrow('documentType', documentType),
  applicationRoleName: valueOrThrow('applicationRoleName', applicationRoleName),
});

const mapContextsToArchive =
  map(context => ({
    ...context,
    ...context.claimInfo,
    id: context.$type === 'ReinsuranceContext' ? undefined : context.id, // ReinsuranceContext uses fake IDs for UI purposes only so we must delete them
  }));

const mapUpdateDocument = (id, documentModel) =>
  eitherTry(() => ({
    id: valueOrThrow('documentId', id),
    notes: toMaybe(documentModel.note).orJust(null),
    classification: valueOrThrow('classification', documentModel.classification),
    taxonomy: mapTaxonomy(documentModel),
    insuranceContexts: valueOrThrow('contexts', mapContextsToArchive(documentModel.contexts)),
    piiSection: documentModel.piiSection
  }));

export default mapUpdateDocument;
