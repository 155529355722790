import {
  replace, pipe, take, drop, toUpper, map
} from 'ramda';

const capitalize = str =>
  pipe(
    take(1),
    toUpper,
    upperChar => upperChar + drop(1, str)
  )(str);

const mapTaxonomyDisplayName =
    pipe(
      replace(/(_|-)/g, ' '),
      capitalize
    );

export const mapDisplayNameValue = tag => ({
  displayName: mapTaxonomyDisplayName(tag),
  value: tag
});

const mapTags = documentTags =>
  map(mapDisplayNameValue, documentTags || []);

export default mapTags;
