import { map, prop } from 'ramda';
import { valueOrThrow, toMaybe, eitherTry } from '../../lib';

const mapTaxonomy = ({ documentType, keywords, applicationRoleName }) => ({
  documentTags: map(prop('value'), keywords),
  documentType: valueOrThrow('documentType', documentType),
  applicationRoleName: valueOrThrow('applicationRoleName', applicationRoleName)
});

const mapContext = (context, insuranceYear) => ({
  ...context,
  ...context.claimInfo,
  id: context.$type === 'ReinsuranceContext' ? undefined : context.id, // ReinsuranceContext uses fake IDs for UI purposes only so we must delete them
  insuranceYear
});

export const mapContextsToArchive = contexts => contexts.reduce((acc, context) =>
  acc.concat(context.$type === 'ReinsuranceContext'
    ? context.yearOptions.filter(option => option.selected).map(option => mapContext(context, option.value))
    : mapContext(context, context.insuranceYear)), []);

const mapArchiveDocument =
  (aadToken, messageId, mailBox, documentModel, skipInvalidFileTypes) =>
    eitherTry(() => ({
      token: valueOrThrow('aadToken', aadToken),
      messageId: valueOrThrow('messageId', messageId),
      mailBox,
      skipInvalidFileTypes,
      notes: toMaybe(documentModel.note).orJust(null),
      classification: valueOrThrow('classification', documentModel.classification),
      taxonomy: mapTaxonomy(documentModel),
      insuranceContexts: valueOrThrow('contexts', mapContextsToArchive(documentModel.contexts)),
      piiSection: documentModel.piiSection,
    }));

export default mapArchiveDocument;
