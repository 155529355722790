/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import { useMsal, useMsalAuthentication } from '@azure/msal-react';
import { InteractionRequiredAuthError, InteractionStatus, InteractionType } from '@azure/msal-browser';
import { scopes } from '../appAuth/msalAuth';
import { getAppInsights } from '../lib';
import { getUserEmail } from './officeHelpers';

const useAuthentication = () => {
  const appInsights = getAppInsights();
  const { instance, inProgress } = useMsal();
  const [loginError, setLoginError] = useState(false);

  const emailAddress = getUserEmail();
  const authenticationRequest = {
    loginHint: emailAddress,
    scopes
  };

  // The useMsalAuthentication hook will initiate a login if a user is not already signed in, otherwise it will attempt to acquire a token.
  const { login, result, error } = useMsalAuthentication(InteractionType.Silent, authenticationRequest);

  const handleError = (err: any) => {
    appInsights.trackException({ exception: err });
    setLoginError(true);
  };

  useEffect(() => {
    if (error instanceof InteractionRequiredAuthError) {
      login(InteractionType.Popup, authenticationRequest)
        .then(response => {
          instance.setActiveAccount(response?.account || null);
          setLoginError(false);
        })
        .catch(e => handleError(e));
    } else if (error) {
      handleError(error);
    }
  }, [error]);

  useEffect(() => {
    if (result?.account) {
      instance.setActiveAccount(result.account);
      setLoginError(false);
    }
  }, [result]);

  const isAuthenticating = inProgress !== InteractionStatus.None;
  return { loginError, isAuthenticating };
};

export default useAuthentication;
