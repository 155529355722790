import { Either } from 'monet';

const eitherTry = (fn: any) => {
  try {
    return Either.Right(fn());
  } catch (e) {
    return Either.Left(e);
  }
};

export default eitherTry;
