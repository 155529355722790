import React from 'react';
import PropTypes from 'prop-types';
import customPropType from '../lib/customPropType';
import { remoteDataShape } from '../lib';

const RemoteData = ({
  type, notAsked, loading, failure, success
}) =>
  type.cata({
    NotAsked: notAsked || (() => null),
    Loading: loading || (() => <span>Loading...</span>),
    Failure: failure,
    Success: success
  });

RemoteData.propTypes = {
  type: PropTypes.shape(remoteDataShape).isRequired,
  notAsked: customPropType.oneParamFunc,
  loading: customPropType.oneParamFunc,
  failure: customPropType.oneParamFunc.isRequired,
  success: customPropType.oneParamFunc.isRequired
};

export default RemoteData;
