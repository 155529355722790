const generateYearOptions = (selectedValues = []) => {
  const thisYear = new Date().getFullYear();
  const yearOptions = [];
  for (let i = thisYear + 2; i >= thisYear - 10; i -= 1) {
    yearOptions.push({
      value: i,
      displayName: i,
      selected: selectedValues.includes(i),
    });
  }
  return yearOptions;
};

export default generateYearOptions;
