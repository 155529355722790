import { mdiLockOpenOutline } from '@mdi/js';
import { ArchivedContexts } from 'its-react-ui';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';
import { routes } from '../../App/Router';
import { PageTitle } from '../../components';
import User from '../../containers/User/index';
import { archivedContentShape, maybeShape, toMaybe } from '../../lib';
import { sortReinsuranceContexts } from '../../lib/reinsuranceUtils';
import vars from '../../scss/variables.scss';
import { Icon } from '../../ui';
import ArchivedBy from './successView/ArchivedBy';
import ArchivedContent from './successView/ArchivedContent';
import styles from './successView/SuccessView.module.scss';
// import { addItemCategoryToItem, hasInsifyCategory } from '../../apis'; // <LEAPDEV-1180>

const Unlock = () => (
  <Link to={routes.edit} className={styles.unlock}>
    <div>
      <Icon path={mdiLockOpenOutline} size={vars.iconSizeLarge} />
    </div>
    <span>Unlock</span>
  </Link>
);

const getContextData = contextList => contextList.length > 0
  ? contextList[0].contextType
    .cata({
      ClaimContext: () => ({ type: 'claim', contexts: contextList }),
      CoverContext: () => ({ type: 'cover', contexts: contextList }),
      ReinsuranceContext: () => ({
        type: 'reinsurance',
        contexts: contextList.sort(sortReinsuranceContexts)
      }),
      SomeOtherContext: () => ({ type: undefined, contexts: contextList }),
    })
  : ({ type: undefined, contexts: [] });

const SuccessView = ({
  content, contextList, archivedAt, archivedBy, originallyArchivedBy,
}) => {
  const { type, contexts } = getContextData(contextList.orJust([]));

  return (
    <>
      <User />
      <div className={styles.container}>
        <div className={styles.content}>
          <PageTitle icon="archivedDoc" title="ARCHIVED DOCUMENT" />
          <ArchivedBy
            maybeUser={archivedBy}
            maybeDate={archivedAt}
            maybeOriginalUser={originallyArchivedBy} />
          <ArchivedContent content={content} />
          <h4>{`Archived under the ${type} context:`}</h4>
          <div>
            <ArchivedContexts maybeContextList={toMaybe(contexts)} />
          </div>
        </div>
        <Unlock />
      </div>
    </>
  );
};
SuccessView.propTypes = {
  content: PropTypes.shape(archivedContentShape).isRequired,
  contextList: PropTypes.shape(maybeShape).isRequired,
  archivedBy: PropTypes.shape(maybeShape).isRequired,
  originallyArchivedBy: PropTypes.shape(maybeShape).isRequired,
  archivedAt: PropTypes.shape(maybeShape).isRequired,
};

export default SuccessView;
