import {
  applyMiddleware, compose, createStore,
} from 'redux';
import createSagaMiddleware from 'redux-saga';
import thunk from 'redux-thunk';
import rootSaga from './rootSaga';
import rootReducer from './rootreducer';

const configureStore = initialState => {
  const sagaMiddleware = createSagaMiddleware();

  const middleware = [
    thunk,
    sagaMiddleware,
  ];

  // In development, use the browser's Redux dev tools extension if installed
  const composeEnhancers = (
    process.env.NODE_ENV === 'development'
    && typeof window !== 'undefined'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
  ) || compose;
  // const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__;

  const reduxStore = createStore(
    rootReducer,
    initialState,
    composeEnhancers(applyMiddleware(...middleware)),
  );

  sagaMiddleware.run(rootSaga);

  return reduxStore;
};

export default configureStore;
