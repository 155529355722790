import 'normalize.css';
import 'react-app-polyfill/ie11';
import 'core-js/features/array/find';
import 'core-js/features/array/includes';
import 'core-js/features/array/for-each';
import 'core-js/features/array/flat-map';
import 'core-js/features/string/includes';
import 'core-js/features/string/repeat';
import 'core-js/features/promise';
import './scss/base.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import BootstrapAddin from './BootstrapAddin';
import { isItemEmail, getAppInsights } from './lib';

// eslint-disable-next-line no-console
console.log('*** starting app');

const render = isEmail => {
  const container = document.getElementById('root');

  ReactDOM.unmountComponentAtNode(container);
  ReactDOM.render(<BootstrapAddin resetStore isItemEmail={isEmail} />, container);
};

window.Office.initialize = () => {
  window.Office.onReady(() => {
    const appInsights = getAppInsights();
    const handleItemChanged = args => {
    /* Remark:
      * eventArgs.initialData must be accessed to make sure that window.Office.context.mailbox.item is
      * initialized early enough while switching from an appointment message to a mail message.
      * In this context (itemChange) it is more reliable to check eventArgs.initialData.itemType rather
      * than using isItemEmail which is an assumption that this is not a message...
      */
      appInsights.trackTrace({
        message: 'handleItemChanged',
        properties: {
          data: JSON.stringify(args)
        }
      });
      const isEmail = (args.initialData.itemType === 1 || args.initialData.itemClass === 'IPM.Note');
      render(isEmail);
    };
    window.Office.context.mailbox.addHandlerAsync(window.Office.EventType.ItemChanged, handleItemChanged,);
    // Office loaded
    appInsights.trackTrace({
      message: 'onReady',
      properties: {
        item: JSON.stringify(window.Office.context.mailbox.item),
        initialData: JSON.stringify(window.Office.context.mailbox.initialData),
        href: window?.location?.href
      }
    });
    const isEmail = isItemEmail(window.Office.context.mailbox.item);
    render(isEmail);
  });
};
