import React from 'react';
import PropTypes from 'prop-types';
import styles from './archivedBy/ArchivedBy.module.scss';
import { dateToMaybe, maybeShape, formatDate } from '../../../lib';
import { Maybe } from '../../../components';

const getArchiveUser = maybeOriginalUser =>
  maybeOriginalUser.cata(
    () => undefined,
    user => ({
      ...user,
      date: dateToMaybe(new Date(user.date))
    })
  );

const getArchivedAt = maybeDate =>
  maybeDate.cata(
    () => '',
    date => ` on ${formatDate(date)}`,
  );

const getLastChangedBy = (archivedByUser, maybeUser, maybeDate) => {
  const now = Date.now();
  const archivedDate = archivedByUser.date.orJust(now);
  if (archivedByUser
    && (maybeDate.orJust(now) - archivedDate) > 5000) {
    return (
      <Maybe type={maybeUser}
             just={user => (
               <div className={styles.archivedBy}>
                 <h4>Last changed by {user}{getArchivedAt(maybeDate)}</h4>
               </div>
             )} />
    );
  }
  return null;
};

const ArchivedBy = ({ maybeUser, maybeDate, maybeOriginalUser }) => {
  const archivedByUser = getArchiveUser(maybeOriginalUser);
  const archivedBy = (
    <div className={styles.archivedBy}>
      <h4>Archived by {archivedByUser.user.name}{getArchivedAt(archivedByUser.date)}</h4>
    </div>
  );

  return (
    <>
      {archivedBy}
      {getLastChangedBy(archivedByUser, maybeUser, maybeDate)}
    </>
  );
};

ArchivedBy.propTypes = {
  maybeUser: PropTypes.shape(maybeShape).isRequired,
  maybeDate: PropTypes.shape(maybeShape).isRequired,
  maybeOriginalUser: PropTypes.shape(maybeShape).isRequired,
};

export default ArchivedBy;
