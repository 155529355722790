import React from 'react';
import vars from '../scss/variables.scss';
import { ReactComponent as ArchiveSvg } from './archive.svg';
import { ReactComponent as ArchivedSvg } from './icon_archived.svg';
import { ReactComponent as ArchivingSvg } from './migration.svg';
import { ReactComponent as AuthenticatingSvg } from './privacy.svg';
import { ReactComponent as BoatSvg } from './boat-front.svg';
import { ReactComponent as CheckOutlineSvg } from './check-outline.svg';
import { ReactComponent as CreateEventSvg } from './event-create.svg';
import { ReactComponent as DocSvg } from './doc.svg';
import { ReactComponent as FindingContextSvg } from './context empty.svg'; // TODO: Should be fixed
import { ReactComponent as LifeRingSvg } from './lifering.svg';
import { ReactComponent as LookingForContextSvg } from './search.svg';
import { ReactComponent as OpeningDrawerSvg } from './archive-drawer.svg';
import { ReactComponent as SearchBoatSvg } from './ic-search-context.svg';
import { ReactComponent as DCheckSvg } from './d-check.svg';
import { ReactComponent as RemoveFileSvg } from './remove-file.svg';
import { ReactComponent as SadFaceSvg } from './cry-15.svg';
import { ReactComponent as ExclamationSvg } from './exclamation.svg';

/* NOTE: Custom icon svgs comes with an initial width and height in px.
 * Use the width and height px's to convert to rem and remove the attributes
 * from the svg.
 */

const pxToRem = px =>
  `${px / vars.fontSizeBasePx}rem`;

const getRemStyleObj = (widthPx, heightPx) => (
  {
    width: pxToRem(widthPx),
    height: pxToRem(heightPx),
  }
);
// NOTE: Need to use rem on the svg el themselves to scale properly
export const DCheckIcon = props => <DCheckSvg {...getRemStyleObj(64, 64)} {...props} />;
export const RemoveFileIcon = props =>
  <RemoveFileSvg {...getRemStyleObj(64, 64)} {...props} />;
export const SadFaceIcon = props =>
  <SadFaceSvg {...getRemStyleObj(64, 64)} {...props} />;
export const DocIcon = props =>
  <DocSvg {...getRemStyleObj(36, 36)} {...props} />;
export const ArchiveIcon = props =>
  <ArchiveSvg {...getRemStyleObj(22, 21)} {...props} />;
export const DocArchivedIcon = props =>
  <ArchivedSvg {...getRemStyleObj(36, 36)} {...props} />;
export const OpeningDrawerIcon = props =>
  <OpeningDrawerSvg {...getRemStyleObj(55, 61)} {...props} />;
export const BoatIcon = props =>
  <BoatSvg {...getRemStyleObj(64, 64)} {...props} />;
export const ArchivingIcon = props =>
  <ArchivingSvg {...getRemStyleObj(63, 56)} {...props} />;
export const FindingContextIcon = props =>
  <FindingContextSvg {...getRemStyleObj(301, 214)} {...props} />;
export const CheckOutlineIcon = props =>
  <CheckOutlineSvg {...getRemStyleObj(16, 11)} {...props} />;
export const AuthenticatingIcon = props =>
  <AuthenticatingSvg {...getRemStyleObj(51, 62)} {...props} />;
export const LookingForContextIcon = props =>
  <LookingForContextSvg {...getRemStyleObj(44, 44)} {...props} />;
export const CreateEventIcon = props =>
  <CreateEventSvg {...getRemStyleObj(60, 62)} {...props} />;
export const SearchBoatIcon = props =>
  <SearchBoatSvg {...getRemStyleObj(59, 59)} {...props} />;
export const LifeRingIcon = props =>
  <LifeRingSvg {...getRemStyleObj(54, 54)} {...props} />;
export const ExclamationIcon = props =>
  <ExclamationSvg {...getRemStyleObj(50, 62)} {...props} />;
