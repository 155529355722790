import React from 'react';
import PropTypes from 'prop-types';
import styles from './errorPage/ErrorPage.module.scss';
import { SadFaceIcon } from '../../assets';

const getMessage = errMessage =>
  errMessage instanceof window.Error
    ? errMessage.message
    : errMessage;

const ErrorPage = ({ message, retryAction, title = "That didn't go well" }) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <SadFaceIcon />
      <h1>{title}</h1>
      <h4>{getMessage(message)}</h4>
    </div>
    {retryAction && <button className={styles.dismissButton} onClick={retryAction}>Retry</button>}
  </div>
);

ErrorPage.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string.isRequired,
  retryAction: PropTypes.func
};

export default ErrorPage;
