import React from 'react';
import PropTypes from 'prop-types';
import { remoteDataShape } from '../lib';
import SuccessView from './archivedPage/SuccessView';
import RemoteData from '../components/RemoteData';
import { FetchingArchivedPage } from '../components/LoadingPages';
import ErrorPage from '../components/ErrorPage';

const ArchivedPage = ({ document, fetchDocument, userCanUnlock }) => (
  <RemoteData type={document}
              notAsked={() => <FetchingArchivedPage />}
              loading={() => <FetchingArchivedPage />}
              failure={_ => (
                <ErrorPage message="Failed to fetch archived document. Please try again."
                           retryAction={fetchDocument} />
              )}
              success={archivedDoc =>
                <SuccessView {...archivedDoc} userCanUnlock={userCanUnlock} />}
  />
);

ArchivedPage.propTypes = {
  document: PropTypes.shape(remoteDataShape).isRequired,
  fetchDocument: PropTypes.func.isRequired,
  userCanUnlock: PropTypes.bool.isRequired,
};

export default ArchivedPage;
