import { Nothing } from 'monet';
import { handleActions } from 'redux-actions';
import { updateSelectedContexts, removeRestrictedContexts, toMaybe } from '../../lib';
import * as a from './constants';
import { FETCH_ANALYSIS_SUCCESS, FETCH_EDIT_DOCUMENT_SUCCESS } from '../DocumentPage/constants';

const defaultState = {
  selectedContexts: Nothing(),
  contextId: '',
  showModal: false
};

const reducer = handleActions({
  [FETCH_ANALYSIS_SUCCESS]: (state, action) => {
    // Claim Handler and Reinsurance role, user gets documentAnalysis.contextList of type Maybe [InsuranceContext]
    // Underwriter                   role, user gets documentAnalysis.contextList of type Maybe [Maybe [InsuranceContext]]
    const { documentAnalysis } = action.payload;
    const contexts = documentAnalysis.contextList.orJust([]);

    return {
      ...state,
      selectedContexts: contexts.length === 1
        ? (!!contexts[0].$type && contexts[0].$type !== 'CoverContext' ? documentAnalysis.contextList : contexts[0])
        : Nothing()
    };
  },

  [FETCH_EDIT_DOCUMENT_SUCCESS]: (state, action) => {
    const { archivedDocument } = action.payload;
    const maybeContexts = archivedDocument.data.contextList;
    return {
      ...state,
      selectedContexts: maybeContexts
    };
  },

  [a.CONTEXT_CLICKED]: (state, action) => {
    const { isSelected, clickedContext, clickedOptions } = action.payload;
    return {
      ...state,
      selectedContexts: toMaybe(updateSelectedContexts(state.selectedContexts.orJust([]), isSelected, clickedContext, clickedOptions)).map(removeRestrictedContexts),
    };
  },

  [a.SET_SELECTED_CONTEXTS]: (state, action) => ({
    ...state,
    selectedContexts: toMaybe(action.payload).map(removeRestrictedContexts),
  }),

  [a.RESET_CONTEXT_LIST]: () => defaultState,

  [a.CLOSE_MODAL]: (state, action) => ({
    ...state,
    showModal: false,
    contextId: '',
  }),

  [a.OPEN_MODAL]: (state, action) => ({
    ...state,
    showModal: true,
    contextId: action.payload,
  })
}, defaultState);

export default reducer;
