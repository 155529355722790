import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getPiiSuggestion, getRestifiedItemId } from '../apis';
import { getAadToken } from '../appAuth/msalAuth';

export const PiiSuggestionStatus = Object.freeze({
  NotAsked: 'NOT_ASKED',
  Loading: 'LOADING',
  Suggest: 'SUGGEST',
  DontSuggest: 'DONT_SUGGEST',
});

export const usePiiSuggestion = isNewDocument => {
  const { user: { data: { mailbox } } } = useSelector(state => state.user);
  const [piiStatus, setPiiStatus] = useState(PiiSuggestionStatus.NotAsked);

  useEffect(() => {
    const doRequest = async () => {
      const aadToken = await getAadToken();
      const emailId = await getRestifiedItemId(window.Office);
      setPiiStatus(PiiSuggestionStatus.Loading);
      try {
        const response = await getPiiSuggestion(emailId, mailbox, aadToken);
        const suggestPiiStr = await response.text();
        const suggestPii = suggestPiiStr === 'true';

        setPiiStatus(suggestPii ? PiiSuggestionStatus.Suggest: PiiSuggestionStatus.DontSuggest);
      } catch (error) {
        setPiiStatus(PiiSuggestionStatus.DontSuggest);
      }
    };

    if (!isNewDocument) {
      // The user is editing a document, no need to suggest Pii status.
      setPiiStatus(PiiSuggestionStatus.DontSuggest);
      return;
    }

    if (piiStatus === PiiSuggestionStatus.NotAsked) {
      // This is a new document, so we perform the Pii check.
      doRequest();
    }
  }, [isNewDocument, mailbox, piiStatus]);

  return piiStatus;
};
