import { toAction, dispatchFetchSuccess } from '../../lib';
import * as a from './constants';

export const dispatchUser = (dispatch, apiUser) => {
  dispatchFetchSuccess(dispatch, a.FETCH_USER_SUCCESS, apiUser);
};

export const roleChanged = (dispatch, role) =>
  dispatch(toAction(a.ROLE_CHANGED, role));

export const fetchUserSagaWatchAction = () => ({
  type: a.USER_WATCH_FETCH_USER,
});

export const fetchRolesSagaWatchAction = () => ({
  type: a.USER_WATCH_FETCH_ROLES,
});
