import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { mdiDotsHorizontal } from '@mdi/js';
import { remoteDataToMaybe, remoteDataShape } from '../../lib';
import { Icon, Dropdown } from '../../ui';
import styles from './User.module.scss';

import RemoteData from '../RemoteData';
import vars from '../../scss/variables.scss';

const getRole = (roles, activeRole) =>
  roles.isSuccess()
    ? activeRole.displayName
    : 'Fetching roles...';

const getUserName = remoteUser =>
  remoteDataToMaybe(remoteUser)
    .cata(
      () => 'Fetching user..',
      user => user.name,
    );

const User = ({
  remoteRoles, activeRole, roleChanged, user, isArchived,
}) => {
  const [showDropdown, toggleShow] = useState(false);

  return (
    <div className={styles.container}>
      <div>
        <span>{getUserName(user)} </span>
        <span>· {getRole(remoteRoles, activeRole)}</span>
      </div>
      <div>
        <RemoteData type={remoteRoles}
                    loading={() => null}
                    failure={_ => <span>Failed to fetch roles</span>}
                    success={roles => (
                      <>
                        <Icon className={styles.dots}
                              path={mdiDotsHorizontal}
                              onClick={() => toggleShow(true)}
                              size={vars.iconSizeMedium} />
                        <Dropdown inactive={isArchived}
                                  inactiveMessage="Changing roles won't affect an archived document"
                                  values={roles}
                                  show={showDropdown}
                                  toggleShow={toggleShow}
                                  onSelect={roleChanged} />
                      </>
                    )} />
      </div>
    </div>
  );
};

User.propTypes = {
  remoteRoles: PropTypes.shape(remoteDataShape).isRequired,
  user: PropTypes.shape(remoteDataShape).isRequired,
  activeRole: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
  }).isRequired,
  roleChanged: PropTypes.func.isRequired,
  isArchived: PropTypes.bool.isRequired,
};

export default User;
