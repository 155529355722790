import { handleActions } from 'redux-actions';
import { RemoteData } from '../../lib';
import * as a from './constants';

const defaultState = {
  unArchiveResult: RemoteData.NotAsked,
};

const reducer = handleActions({
  [a.UNARCHIVE_DOCUMENT]: state => ({
    ...state,
    unArchiveResult: RemoteData.Loading,
  }),
  [a.UNARCHIVE_RESET]: () => defaultState,

  [a.UNARCHIVE_DOCUMENT_SUCCESS]: (state, action) => ({
    ...state,
    unArchiveResult: RemoteData.Success(action.payload),
  }),
  [a.UNARCHIVE_DOCUMENT_ERROR]: (state, action) => ({
    ...state,
    unArchiveResult: RemoteData.Failure(action.payload),
  }),
}, defaultState);

export default reducer;
