import React from 'react';
import { LookingForContextIcon } from '../../assets';
import styles from './OpeningAddinPage.module.scss';

const LookingForContextPage = () => (
  <div className={styles.container}>
    <LookingForContextIcon />
    <h1>Looking for context</h1>
  </div>
);

export default LookingForContextPage;
