import { toAction } from '../../lib';
import * as actions from './constants';

const fetchIsArchivedSagaWatchAction = mailBox =>
  toAction(
    actions.DOCUMENT_WATCH_CHECK_IS_ARCHIVED,
    {
      mailBox,
    }
  );

export default fetchIsArchivedSagaWatchAction;
