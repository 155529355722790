import { takeLatest, call, put } from 'redux-saga/effects';
import { getIsArchived } from '../../apis/';
import * as a from './constants';
import * as office from '../../apis/office';
import { SET_SHOULD_REDIRECT } from '../ArchivedPage/constants';
import { getAadToken } from '../../appAuth/msalAuth';
import { getAppInsights, toAction } from '../../lib';

const appInsights = getAppInsights();

export function* documentStatusFetchIsArchived(args) {
  yield put(toAction(a.FETCH_DOCUMENT_ARCHIVED));
  try {
    const aadToken = yield call(getAadToken);
    const itemId = yield call(office.getRestifiedItemId, window.Office);
    try {
      const { payload: { mailBox } } = args;
      yield call(getIsArchived, itemId, aadToken, mailBox);
      yield put(toAction(a.FETCH_DOCUMENT_ARCHIVED_SUCCESS, true));
      appInsights.trackTrace({
        message: 'FetchIsArchived = success',
        properties: {
          subject: window.Office.context.mailbox.item.subject,
          internetMessageId: window.Office.context.mailbox.item.internetMessageId
        }
      });
    } catch (err) {
      if (err.status === 404) {
        yield put(toAction(SET_SHOULD_REDIRECT, false));
        yield put(toAction(a.FETCH_DOCUMENT_ARCHIVED_SUCCESS, false));
      } else {
        yield put(toAction(a.FETCH_DOCUMENT_ARCHIVED_ERROR, err));
      }
      appInsights.trackTrace({
        message: `FetchIsArchived = error, ${err.message}`,
        properties: {
          subject: window.Office.context.mailbox.item.subject,
          internetMessageId: window.Office.context.mailbox.item.internetMessageId
        }
      });
    }
  } catch (_) {
    yield put(toAction(a.FETCH_DOCUMENT_ARCHIVED_ERROR));
  }
}

export function* documentStatusFetchIsArchivedSagaWatchSaga() {
  yield takeLatest(a.DOCUMENT_WATCH_CHECK_IS_ARCHIVED, documentStatusFetchIsArchived);
}
