import React from 'react';
import PropTypes from 'prop-types';
import Archiving from './unarchivePage/Archiving';
import Failure from './unarchivePage/Failure';
import NotAsked from './unarchivePage/NotAsked';
import RemoteData from '../RemoteData';
import Success from './unarchivePage/Success';
import { remoteDataShape } from '../../lib';
import { getAadToken } from '../../appAuth/msalAuth';
import * as office from '../../apis/office';
import { routes } from '../../App/Router';

const UnarchivePage = props => {
  const resetAndNavigate = () => {
    props.resetApp();
    props.history.push(routes.root);
  };

  const handleOnUnArchive = () => {
    const messageId = office.getRestifiedItemId(window.Office);
    const { item } = window.Office.context.mailbox;
    getAadToken()
      .then(aadToken => {
        props.unArchiveDocument({
          documentId: props.documentId,
          aadToken,
          messageId,
          item,
        });
      });
  };

  return (
    <RemoteData type={props.unArchiveResult}
                notAsked={() => (
                  <NotAsked
                    onRegret={props.onClose}
                    onUnArchive={handleOnUnArchive} />
                )}
                loading={Archiving}
                failure={_ => (
                  <Failure onClose={() => {
                    props.onClose();
                    props.cancelAfterFailure();
                  }} />
                )}
                success={_ => <Success onClose={resetAndNavigate} />} />
  );
};

UnarchivePage.propTypes = {
  cancelAfterFailure: PropTypes.func.isRequired,
  documentId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  resetApp: PropTypes.func.isRequired,
  unArchiveDocument: PropTypes.func.isRequired,
  unArchiveResult: PropTypes.shape(remoteDataShape).isRequired,
};

export default UnarchivePage;
