export default class RequestError extends Error {
  constructor(status, message) {
    super(`${status} - ${message}`);

    this.status = status;

    if (Object.prototype.hasOwnProperty.call(Error, 'captureStackTrace')) {
      Error.captureStackTrace(this, RequestError);
    }
  }
}
