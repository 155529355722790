import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import getMetaValue from './getMetaValue';

const initAppInsights = () => {
  const accountId = window.Office?.context?.mailbox?.userProfile?.emailAddress || 'na';

  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: getMetaValue(document, 'applicationInsightsKey'),
      maxBatchInterval: 30000,
      accountId,
      enableAutoRouteTracking: true
    }
  });
  appInsights.loadAppInsights();

  const telemetryInitializer = envelope => {
    // eslint-disable-next-line no-param-reassign
    envelope.data.application = 'Dms.Outlook.AddIn';
  };
  appInsights.addTelemetryInitializer(telemetryInitializer);

  return appInsights;
};

const getAppInsights = () => {
  if (!window.appInsights) window.appInsights = initAppInsights();
  return window.appInsights;
};

export default getAppInsights;
