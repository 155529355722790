import { pipe, curry } from 'ramda';
import maybeProp from './maybeProp';

const objHasField = curry(
  (propToFind, objToReturn) =>
    pipe(
      maybeProp,
      maybe => maybe.map(_ => objToReturn)
    )(propToFind, objToReturn)
);

export default objHasField;
