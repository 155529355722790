import React from 'react';
import PropTypes from 'prop-types';
import MdiIcon from '@mdi/react';
import cn from 'classnames';
import styles from './Icon.module.scss';

const getClasses = className =>
  cn({ [styles.container]: !className }, className);

const Icon = ({ path, className, ...props }) =>
  <MdiIcon path={path} {...props} className={getClasses(className)} />;

Icon.propTypes = {
  path: PropTypes.string.isRequired,
  className: PropTypes.string
};

Icon.defaultProps = {
  className: ''
};

export default Icon;
