import {
  map, pipe, prop, sortBy
} from 'ramda';

const mapClassifications =
  map(classification => {
    const name = classification && classification.name;

    return {
      value: name,
      displayName: name,
      selected: classification && classification.default,
      priority: (classification && classification.priority) || 0
    };
  });

const mapAndSortClassifications =
  pipe(
    mapClassifications,
    sortBy(prop('priority'))
  );

export default mapAndSortClassifications;
