import { curry } from 'ramda';
import { toAction } from '../../lib';
import * as a from './constants';

export const resetApp =
  toAction(a.RESET_APP);

/* TODO: Reloading here is not optimal, should be improved.
 * Should group isArchived, user and roles reqs with
 * Promise.all instead. User will fail if Email returned
 * is empty/null etc.
 * Then is user is the req that failed, do reAuth
*/
export const reAuth = dispatch => {
  window.sessionStorage.clear();
  window.localStorage.clear();

  // TODO: No practical effect atm.
  dispatch(toAction(a.RE_AUTH));

  window.location.reload();
};

export const openModal = curry(
  (dispatch, modalData) =>
    dispatch(toAction(a.OPEN_MODAL, modalData))
);

export const setDocumentMode = (dispatch, isEditing) =>
  dispatch(toAction(a.SET_DOCUMENT_MODE, { editMode: isEditing }));
