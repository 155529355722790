import React from 'react';
import { ArchivingIcon } from '../../assets';
import styles from './OpeningAddinPage.module.scss';

const ArchivingDocumentPage = () => (
  <div className={styles.container}>
    <ArchivingIcon />
    <h1>Archiving</h1>
  </div>
);

export default ArchivingDocumentPage;
