import { prop } from 'ramda';
import { post } from './base';
import { jsonOrThrow } from './core';
import { mapMaybeTaxonomy, mapTaxonomyToDocTypesResp, mapDocumentTypes } from './mappers';

const fetchSuggestions = (applicationRole, documentType, note) =>
  post('/api/analysis/note/', {}, { applicationRole, documentType, note })
    .then(jsonOrThrow)
    .then(mapMaybeTaxonomy)
    .then(mapTaxonomyToDocTypesResp)
    .then(maybeTaxonomy =>
      maybeTaxonomy
        .map(mapDocumentTypes)
        .map(prop('tags'))
        .orJust([]));

export default fetchSuggestions;
