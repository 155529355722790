import { handleActions } from 'redux-actions';
import * as a from './constants';
import getMetaValue from '../../lib/getMetaValue';

const defaultState = {
  modal: {
    show: false,
    title: '',
    content: '',
  },
  modalMessage: {
    show: false,
    header: '',
    content: '',
    postCloseAction: undefined,
    postCloseArgs: {},
  },
  editMode: false,
  appVersion: getMetaValue(document, 'version'),
};

const reducer = handleActions({
  [a.OPEN_MODAL]: (state, { payload }) => ({
    ...state,
    modal: {
      show: true,
      title: payload.title,
      content: payload.content,
    },
  }),

  [a.OPEN_MODAL_MESSAGE]: (state, { payload }) => ({
    ...state,
    modalMessage: {
      show: true,
      header: payload.header,
      content: payload.content,
      postCloseAction: payload.postCloseAction,
      postCloseArgs: payload.postCloseArgs,
    },
  }),

  [a.CLOSE_MODAL_MESSAGE]: state => ({
    ...state,
    modalMessage: {
      show: false,
      title: '',
      content: '',
      postCloseAction: undefined,
      postCloseArgs: {},
    },
  }),

  [a.SET_DOCUMENT_MODE]: (state, action) => ({
    ...state,
    editMode: action.payload.editMode,
  }),

  [a.SET_APP_VERSION]: (state, action) => ({
    ...state,
    appVersion: action.payload.appVersion,
  }),
}, defaultState);

export default reducer;
