import React from 'react';
import LoginErrorPage from '../../pages/LoginErrorPage';
import { AuthenticatingIcon } from '../../assets';
import styles from './OpeningAddinPage.module.scss';
import useAuthentication from '../../lib/useAuthentication';

const AuthenticatingPage = () => {
  const { loginError } = useAuthentication();

  if (loginError) {
    return <LoginErrorPage />;
  }

  return (
    <div className={styles.container}>
      <AuthenticatingIcon />
      <h1>Authenticating</h1>
    </div>
  );
};

export default AuthenticatingPage;
