import { get } from './base';
import { mapUser, mapMailbox } from './mappers';

const getUser = () =>
  get('/api/user')
    .then(userResp =>
      mapMailbox(window.Office, userResp.email)
        .then(mailbox => ({
          ...userResp,
          mailbox,
          userMailbox: userResp.email,
        })))
    .then(mapUser);

export default getUser;
