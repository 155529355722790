import React from 'react';
import { routes } from '../App/Router';
import ErrorPage from '../components/ErrorPage';

const redirectToRoot = () => {
  window.location.href = routes.root;
};

const LoginErrorPage = () =>
  <ErrorPage message="Unable to log in." retryAction={redirectToRoot} />;

export default LoginErrorPage;
