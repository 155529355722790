import {
  takeLatest, call, put, select
} from 'redux-saga/effects';
import { toAction } from '../../lib';
import { get } from '../../apis/base';
import * as c from './constants';

const getCoverInfo = coverId =>
  get(`/api/coverInfo/${coverId}`);

const getCoverInfoFromStore = state => state.coverInfo;

export function* getCoverInfoSaga(arg) {
  const { payload: { coverId } } = arg;

  const coverInfo = yield select(getCoverInfoFromStore);
  if (coverInfo.data[coverId]) return;

  try {
    yield put(toAction(c.COVER_INFO_GET, { id: coverId }));
    const response = yield call(getCoverInfo, coverId);
    yield put(toAction(c.COVER_INFO_GET_SUCCESS, { ...response }));
  } catch (err) {
    yield put(toAction(c.COVER_INFO_GET_FAILED, {
      id: coverId,
      err: { ...err },
    }));
  }
}

export function* getCoverInfosWatcherSaga() {
  yield takeLatest(c.WATCH_GET_COVER_INFO, getCoverInfoSaga);
}
