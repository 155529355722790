import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import { useIsAuthenticated } from '@azure/msal-react';
import { AuthenticatingPage } from '../LoadingPages';

const ProtectedRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = useIsAuthenticated();

  return (
    <Route {...rest} render={props => isAuthenticated ? <Component {...props} /> : <AuthenticatingPage />} />
  );
};

ProtectedRoute.propTypes = {
  component: PropTypes.object.isRequired
};

export default ProtectedRoute;
