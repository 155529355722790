import { combineReducers } from 'redux';
import { RE_AUTH, RESET_APP } from '../containers/App/constants';
import app from '../containers/App/reducer';
import archivedPageReducer from '../containers/ArchivedPage/reducer';
import contextListReducer from '../containers/ContextList/reducer';
import contextNavReducer from '../containers/ContextNav/reducer';
import coverInfoReducer from '../containers/CoverInfo/reducer';
import documentPageReducer, { defaultState as documentDefaultState } from '../containers/DocumentPage/reducer';
import documentStatusReducer from '../containers/DocumentStatusPage/reducer';
import searchPageReducer from '../containers/SearchPage/reducer';
import unarchivePageReducer from '../containers/UnarchivePage/reducer';
import userReducer from '../containers/User/reducer';

const appReducer = combineReducers({
  archivedPage: archivedPageReducer,
  contextList: contextListReducer,
  contextNav: contextNavReducer,
  coverInfo: coverInfoReducer,
  documentPage: documentPageReducer,
  documentStatus: documentStatusReducer,
  searchPage: searchPageReducer,
  unarchivePage: unarchivePageReducer,
  user: userReducer,
  app,
});

const rootReducer = (state, action) => {
  switch (action.type) {
    case RESET_APP:
      return appReducer({
        user: state.user,
        documentPage: {
          ...documentDefaultState,
          roleClassifications: state.documentPage.roleClassifications,
          roleDocumentTypes: state.documentPage.roleDocumentTypes,
        }
      }, action);

    case RE_AUTH:
      return appReducer({}, action);

    default:
      return appReducer(state, action);
  }
};

export default rootReducer;
