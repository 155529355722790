import PropTypes from 'prop-types';
import React from 'react';
import { RemoveFileIcon } from '../../../assets';
import styles from './Unarchive.module.scss';

const NotAsked = ({ onRegret, onUnArchive }) => (
  <>
    <RemoveFileIcon className={styles.icon} />
    <h4 className="bold">Are you sure you want to unarchive?</h4>
    <h4>Unarchiving a document makes it unavailable in the Document search portal.</h4>
    <div className={styles.buttonFlex}>
      <button className={styles.cancel}
              type="button"
              onClick={onRegret}>
        <span>Cancel</span>
      </button>
      <button className={styles.doUnarchive}
              type="button"
              onClick={onUnArchive}>
        <span>Unarchive</span>
      </button>
    </div>
  </>
);

NotAsked.propTypes = {
  onRegret: PropTypes.func.isRequired,
  onUnArchive: PropTypes.func.isRequired,
};

export default NotAsked;
