import { unAuthPost, jsonOrThrow } from '../apis/core';
import stores from './storage';
import getStsUrl from '../lib/getStsUrl';
import tokenType from '../lib/constants';

const getGrantType = (authMethod: string) =>
  authMethod === tokenType.SSO
    ? 'external'
    : 'graph';

const getReqBody = (aadToken: string, authMethod: string) => ({
  client_id: 'dms-outlook-addin:app',
  grant_type: getGrantType(authMethod),
  provider: 'aadcommon',
  scopes: 'openid profile dms-outlook-api',
  token: aadToken,
});

const stsConnectUrl = `${getStsUrl(document)}/connect/token`;

const fetchItsToken = (aadToken: string, authMethod: string) =>
  unAuthPost(stsConnectUrl, getReqBody(aadToken, authMethod))
    .then(jsonOrThrow)
    .then(json => json.access_token)
    .then(stores.storeItsToken)
    .then(result =>
      result.cata(
        (err: any) => {
          throw new Error(err); // Explicitly throw to end up in caller's catch
        },
        (_: any) =>
          null, // Token stored successfully
      ));

export default fetchItsToken;
