import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { ContextSearch } from 'its-react-ui';
import { Nothing } from 'monet';
import ContextList from '../containers/ContextList';
import { ContextSearchHelpText } from '../ui';
import {
  roleShape, isUnderwriter, initialContextsSelection, toMaybe
} from '../lib';

const SearchPage = props => {
  const { app: { editMode }, contextList: { selectedContexts: maybeSelectedContexts } } = useSelector(state => state);
  const isGrouped = !props.hasArchived && isUnderwriter(props.activeRole.role);
  return (
    <ContextSearch {...props}
      onBackButtonClicked={props.history.goBack}
      renderHelpText={p => <ContextSearchHelpText {...p} />}
      renderContextList={contextList => (
        <ContextList isGrouped={isGrouped}
          contextList={isGrouped ? contextList : initialContextsSelection(contextList, maybeSelectedContexts.orJust([]), !editMode)}
          verticalListing
          contextGroups={isGrouped ? toMaybe(contextList) : Nothing()}
          renderAsSearchResult={!editMode}
        />
      )}
    />
  );
};

SearchPage.propTypes = {
  activeRole: PropTypes.shape(roleShape).isRequired,
  hasArchived: PropTypes.bool.isRequired,
  helpText: PropTypes.string.isRequired,
  isClaimHandler: PropTypes.bool.isRequired,
  forceListResult: PropTypes.bool.isRequired,
  history: PropTypes.object.isRequired,
};

export default SearchPage;
