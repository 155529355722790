import React from 'react';
import PropTypes from 'prop-types';
import { mdiLightbulbOutline } from '@mdi/js';
import Icon from './Icon';
import { roleShape, isClaimsHandler, isUnderwriter } from '../lib';
import vars from '../scss/variables.scss';
import styles from './ContextSearchHelpText.module.scss';

const getSearchPrefixes = role => {
  if (isClaimsHandler(role)) {
    return [
      { key: 0, prefix: 'vessel', type: 'name,' },
      { key: 1, prefix: 'claim', type: 'number or' },
      { key: 2, prefix: 'imo', type: 'number' }
    ];
  }
  if (isUnderwriter(role)) {
    return [
      { key: 0, prefix: 'vessel', type: 'name,' },
      { key: 1, prefix: 'fleet', type: 'name,' },
      { key: 2, prefix: 'risk', type: 'number,' },
      { key: 3, prefix: 'covernote', type: 'number or' },
      { key: 4, prefix: 'imo', type: 'number' }
    ];
  }
  return null;
};

const ContextSearchHelpText = ({ activeRole }) => {
  const searchPrefixes = getSearchPrefixes(activeRole.role);
  return searchPrefixes && (
    <div className={styles.container}>
      <Icon path={mdiLightbulbOutline} size={vars.iconSizeLarge} />
      <p>
        Use search prefixes such as<br />
        {searchPrefixes.map(x => <span key={x.key}> <strong>{x.prefix}:</strong> {x.type}</span>)}
      </p>
    </div>
  );
};

ContextSearchHelpText.propTypes = {
  activeRole: PropTypes.shape(roleShape).isRequired
};

export default ContextSearchHelpText;
