import { last, dropLastWhile } from 'ramda';
import getMetaValue from './getMetaValue';

const getStsUrl = document => {
  const url = getMetaValue(document, 'sts');

  return last(url) === '/'
    ? dropLastWhile(chr => chr === '/', url)
    : url;
};

export default getStsUrl;
