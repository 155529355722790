import { map } from 'ramda';
import { toMaybe } from '../../lib';

const mapRole = role => ({
  displayName: (role && (role.displayName || role.name)) || 'Unknown',
  value: (role && role.name) || null
});

// Note: Roles should be a list of obj { name: 'RoleName' }.
// Use empty arr if value is falsy
const mapRoles = roles => {
  const rolesOrDefault = toMaybe(roles).orJust([]);

  return map(mapRole, rolesOrDefault);
};

export default mapRoles;
