import { curry } from 'ramda';
import { toAction } from '../../lib';
import * as a from './constants';

export const activeContextChanged = curry(
  (dispatch, contextList, direction) =>
    dispatch(toAction(a.ACTIVE_CONTEXT_CHANGED, { direction, contextList }))
);

export const resetContextNav = dispatch =>
  () => dispatch(toAction(a.RESET_CONTEXT_NAV));

export const resetActiveIndex = dispatch =>
  () => dispatch(toAction(a.RESET_ACTIVE_INDEX));
