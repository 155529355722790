import { toMaybe, eitherTry } from '../lib';

const valueOrThrow = (name, val) =>
  eitherTry(() =>
    toMaybe(val).just())
    .cata(
      _ => { throw new Error(`Required field ${name} is missing`); },
      value => value
    );

export default valueOrThrow;
