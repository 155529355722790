import { handleActions } from 'redux-actions';
import daggy from 'daggy';
import { RemoteData } from '../../lib';
import * as a from './constants';

const DocumentState = daggy.taggedSum('Archived', {
  Archived: [],
  NotArchived: []
});

const documentStatusState = {
  documentStatus: RemoteData.NotAsked
};

const reducer = handleActions({
  [a.FETCH_DOCUMENT_ARCHIVED]: state => ({
    ...state, documentStatus: RemoteData.Loading
  }),

  [a.FETCH_DOCUMENT_ARCHIVED_ERROR]: (state, action) => ({
    ...state, documentStatus: RemoteData.Failure(action.payload)
  }),

  [a.FETCH_DOCUMENT_ARCHIVED_SUCCESS]: (state, action) => {
    const documentState =
      action.payload
        ? DocumentState.Archived
        : DocumentState.NotArchived;

    return { ...state, documentStatus: RemoteData.Success(documentState) };
  }
}, documentStatusState);

export default reducer;
