import * as yup from 'yup';
import { validate } from '../../lib';

const documentSchema = yup.object().shape({
  documentType: yup.string().required('Please select a Document Type'),
  classification: yup.string().required('Please select a Classification'),
  contexts: yup.array().min(1, 'Select one or more contexts'),
  piiSection: yup.mixed().nullable().when('$isPiiChecked', (isPiiChecked, schema) => isPiiChecked ? schema.required('Select PII section') : schema)
});

const validateDocument = (model, showValidationResult, validationContext = {}) => ({
  ...validate(documentSchema, model, validationContext), showValidationResult
});

export default validateDocument;
