import { withRouter } from 'react-router';
import SearchPage from '../../pages/SearchPage';
import * as a from './actions';
import * as app from '../App/actions';
import { getApplicationRoleName } from '../DocumentPage';
import { isClaimsHandler, isUnderwriter, connectWithInit } from '../../lib';

const getActiveRole = (archivedPage, user) => {
  if (archivedPage.hasArchived) {
    const archivedRole =
        getApplicationRoleName(archivedPage.document.data, user.remoteRoles.data);
    return {
      role: archivedRole.value,
      displayName: archivedRole.displayName,
    };
  }
  return {
    role: user.activeRole.value,
    displayName: user.activeRole.displayName,
  };
};

const getHelpText = role => {
  if (isUnderwriter(role)) return 'Search by text or numbers. You can search with vessel name, risk number, cover note, fleet name or IMO';
  if (isClaimsHandler(role)) return 'Search by text or numbers. You can search with vessel name, claim number or IMO.';
  return 'Search by text or numbers. You can search with company name or company number';
};

const mapStateToProps = ({ searchPage, user, archivedPage }) => {
  const activeRole = getActiveRole(archivedPage, user);

  return {
    ...searchPage,
    activeRole,
    hasArchived: archivedPage.hasArchived,
    forceListResult: archivedPage.hasArchived && isUnderwriter(activeRole.role),
    isClaimHandler: isClaimsHandler(activeRole.role),
    helpText: getHelpText(activeRole.role)
  };
};

const mapDispatchToProps = dispatch => ({
  fetchSearchResult: (searchText, searchForFinished, role, forceListResult) => dispatch(a.fetchSearchResult(searchText, searchForFinished, role, forceListResult)),
  searchTextChanged: a.searchTextChanged(dispatch),
  openModal: app.openModal(dispatch),
  clearSearchString: a.clearSearchString(dispatch),
  dispatch,
});

const init = ({ dispatch }) => {
  dispatch(a.resetSearch);
};

export default connectWithInit(
  init,
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(SearchPage));
