import { all } from 'redux-saga/effects';
import {
  userFetchRolesWatcherSaga,
  userFetchUserWatcherSaga,
} from '../containers/User/sagas';
import { documentStatusFetchIsArchivedSagaWatchSaga } from '../containers/DocumentStatusPage/sagas';
import {
  fetchEditDocumentWatchSaga,
  getAnalysisWatchSaga,
  updateDocumentWatchSaga,
  updateTaxonomyWatchSaga,
  validateAttachmentsWatchSaga,
}
  from '../containers/DocumentPage/sagas';
import { fetchDocumentSagaWatchSaga } from '../containers/ArchivedPage/sagas';
import { getCoverInfosWatcherSaga } from '../containers/CoverInfo/sagas';

export default function* rootSaga() {
  yield all([
    documentStatusFetchIsArchivedSagaWatchSaga(),
    fetchDocumentSagaWatchSaga(),
    fetchEditDocumentWatchSaga(),
    getAnalysisWatchSaga(),
    getCoverInfosWatcherSaga(),
    updateDocumentWatchSaga(),
    updateTaxonomyWatchSaga(),
    userFetchRolesWatcherSaga(),
    userFetchUserWatcherSaga(),
    validateAttachmentsWatchSaga(),
  ]);
}
