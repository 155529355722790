import React from 'react';
import PropTypes from 'prop-types';
import {
  pipe, values, filter, map
} from 'ramda';
import { mdiAlert } from '@mdi/js';
import { Icon } from '../../ui';
import styles from './ValidationErrors.module.scss';
import vars from '../../scss/variables.scss';

const ValidationError = ({ message }) => (
  <div className={styles.error}>
    <Icon path={mdiAlert} size={vars.iconSizeMedium} />
    <span>{message}</span>
  </div>
);

ValidationError.propTypes = {
  message: PropTypes.string.isRequired
};

const mapValidationErrors =
  pipe(
    values,
    filter(field => !field.valid),
    map(({ message }) => (
      <ValidationError key={message} message={message} />
    ))
  );

const ValidationErrors = ({ fields }) => (
  <div className={styles.container}>
    {mapValidationErrors(fields)}
  </div>
);

ValidationErrors.propTypes = {
  fields: PropTypes.object.isRequired
};

export default ValidationErrors;
