import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Redirect } from 'react-router-dom';
import { getAppInsights, remoteDataShape } from '../lib';
import apiGetUser from '../apis/user';
import { dispatchUser } from '../containers/User/actions';
import { OpeningAddinPage } from '../components/LoadingPages';
import ErrorPage from '../components/ErrorPage';
import RemoteData from '../components/RemoteData';
import { routes } from '../App/Router';

const appInsights = getAppInsights();

const DocumentStatusPage = ({
  initialData,
  fetchInitialData,
  user,
  dispatch, fetchIsArchived,
}) => {
  const userData = user.user.data;
  useEffect(() => {
    const currentUser = user.user;
    const getUser = currUser => {
      const updatedUser = currUser;
      apiGetUser()
        .then(res => {
          appInsights.trackTrace({
            message: 'OpenEmail',
            properties: {
              user: res.mailbox,
              mailbox: currUser.data.mailbox,
              subject: window.Office.context.mailbox.item.subject,
              internetMessageId: window.Office.context.mailbox.item.internetMessageId
            }
          });
          if (res.mailbox !== currUser.data.mailbox) {
            dispatchUser(dispatch, res);
            updatedUser.data.mailbox = res.mailbox;
          }
          return updatedUser;
        })
        .then(u => fetchIsArchived(u));
    };
    if (initialData.isNotAsked() && currentUser.isSuccess()) {
      getUser(currentUser);
    }
  }, [initialData, user]);

  if (userData && userData.roles.length === 1 && userData.roles[0].value === 'ComplianceOfficer') {
    return <ErrorPage title="Unsupported role" message="Your role as Compliance Officer is not supported." />;
  }

  return (
    <RemoteData type={initialData}
                notAsked={() => (<OpeningAddinPage />)}
                loading={() => <OpeningAddinPage />}
                failure={error => {
                  let title = 'That didn\'t go well';
                  let message = 'Failed to check if document is archived';
                  if (error.toString().includes('403')) {
                    title= 'You don\'t have permission to use this addin.';
                    message = 'Contact system administrator.';
                  }

                  return (
                    <ErrorPage message={message}
                      title={title}
                      retryAction={fetchInitialData} />
                  );
                }}
                success={archived =>
                  archived.cata({
                    Archived: () => <Redirect push to={routes.archived} />,
                    NotArchived: () => <Redirect push to={routes.new} />,
                  })} />
  );
};

DocumentStatusPage.propTypes = {
  initialData: PropTypes.shape(remoteDataShape).isRequired,
  fetchInitialData: PropTypes.func.isRequired,
  fetchIsArchived: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape(remoteDataShape).isRequired,
};

export default DocumentStatusPage;
