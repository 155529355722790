import { handleActions } from 'redux-actions';
import * as a from './constants';

const defaultState = {
  activeContextIndex: 0
};

const reducer = handleActions({
  [a.ACTIVE_CONTEXT_CHANGED]: (state, action) => ({
    ...state,
    activeContextIndex: action.payload.direction.cata({
      Prev: () => Math.max(0, state.activeContextIndex - 1),
      Next: () =>
        Math.min(state.activeContextIndex + 1, action.payload.contextList.length - 1)
    })
  }),

  [a.RESET_CONTEXT_NAV]: (state, action) => defaultState,

  [a.RESET_ACTIVE_INDEX]: (state, action) => ({
    ...state, activeContextIndex: 0
  }),

  [a.UPDATE_ACTIVE_INDEX]: (state, { payload }) => ({
    activeContextIndex: payload.isValue && payload.val.length === state.activeContextIndex
      ? Math.max(0, state.activeContextIndex - 1)
      : state.activeContextIndex
  })
}, defaultState);

export default reducer;
