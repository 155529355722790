import RequestError from '../lib/RequestError';

export const getEncodedFormData = params => Object.keys(params)
  .map(key =>
    `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`)
  .join('&');

export const jsonOrThrow = resp => {
  if (resp.ok) {
    return resp.json();
  }
  throw new Error(`${resp.status} - ${resp.statusText}`);
};

export const respOkOrThrow = resp => {
  if (resp.ok) {
    return resp;
  }

  throw new RequestError(resp.status, resp.statusText);
};

export const unAuthPost = (url, body) =>
  fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
    },
    body: getEncodedFormData(body),
  });
