import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { mdiMagnify } from '@mdi/js';
import { Contexts } from 'its-react-ui';
import { Nothing } from 'monet';
import ContextList from '../../../containers/ContextList/index';
import ContextNav from '../../../containers/ContextNav/index';
import { initialContextsSelection } from '../../../lib';
import { Icon } from '../../../ui';
import vars from '../../../scss/variables.scss';
import { routes } from '../../../App/Router';

const InsuranceContexts = ({
  contextList, isGrouped = false, toggleContextListing, validation
}) => {
  const { app: { editMode }, contextList: { selectedContexts: maybeSelectedContexts }, documentPage: { documentAnalysis: { data }, verticalListing } } = useSelector(state => state);
  const insuranceContexts = isGrouped
    ? (data.contextList.isValue && data.contextList.orJust([]).length > 0 ? data.contextList : Nothing())
    : contextList;
  return (
    <Contexts
      contextList={insuranceContexts}
      toggleContextListing={toggleContextListing}
      verticalListing={verticalListing}
      validation={validation}
      iconLinkToSearch={(
        <Link to={routes.search}>
          <Icon path={mdiMagnify} size={vars.iconSizeMedium} />
        </Link>
      )}
      textLinkToSearch={<Link to={routes.search}>search for it</Link>}
      renderContextsAndNavigator={contexts => (
        <>
          <ContextList isGrouped={isGrouped}
            contextGroups={data.contextList}
            contextList={isGrouped ? contexts : initialContextsSelection(contexts, maybeSelectedContexts.orJust([]), !editMode)}
            verticalListing={verticalListing}
            renderAsSearchResult={!editMode}
          />
          {!verticalListing && <ContextNav isGrouped={isGrouped} contextList={contexts} />}
        </>
      )}
    />
  );
};
InsuranceContexts.propTypes = {
  contextList: PropTypes.oneOfType([PropTypes.array, PropTypes.object]).isRequired,
  isGrouped: PropTypes.bool,
  toggleContextListing: PropTypes.func.isRequired,
  validation: PropTypes.object,
};

export default InsuranceContexts;
