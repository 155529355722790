import React, {
  createContext, useContext, useEffect, useState
} from 'react';
import PropTypes from 'prop-types';
import { useIsAuthenticated } from '@azure/msal-react';
import getFeatureFlags from '../apis/configuration';

export const FeatureEnum = Object.freeze({
  PII: 'enablePii',
});

const FeatureFlagContext = createContext({});

export const useFeatureFlag = featureEnum => {
  const featureFlagContext = useContext(FeatureFlagContext);
  return !!featureFlagContext[featureEnum];
};

export const FeatureFlagProvider = ({ children }) => {
  const [featureFlags, setFeatureFlags] = useState({});
  const isAuthenticated = useIsAuthenticated();

  useEffect(() => {
    const fetchFeatureFlags = async () => {
      try {
        const flags = await getFeatureFlags();
        setFeatureFlags(flags);
      } catch (error) {
        // Fail silently, all toggleable features disabled
      }
    };

    if (isAuthenticated) {
      fetchFeatureFlags();
    }
  }, [isAuthenticated]);

  return (
    <FeatureFlagContext.Provider value={featureFlags}>
      {children}
    </FeatureFlagContext.Provider>
  );
};

FeatureFlagProvider.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]).isRequired
};
