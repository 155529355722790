import { map } from 'ramda';
import { stringTrimToLowerMatches } from '../../../lib';

const updateClassifications = (classifications, maybeArchivedClassification) =>
  maybeArchivedClassification.cata(
    () => classifications,
    classification =>
      map(c => ({
        ...c,
        selected: stringTrimToLowerMatches(c.value, classification)
      }), classifications)
  );

export default updateClassifications;
