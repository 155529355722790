import {
  takeLatest, call, put
} from 'redux-saga/effects';
import {
  FETCH_DOCUMENT,
  FETCH_DOCUMENT_ERROR,
  WATCH_FETCH_DOCUMENT,
  FETCH_DOCUMENT_SUCCESS,
} from './constants';
import * as office from '../../apis/office';
import { getDocument } from '../../apis';
import { getAadToken } from '../../appAuth/msalAuth';
import {
  isReinsuranceContextsList, setIdPerYearAndBusinessAccountId, toMaybe, toAction
} from '../../lib';

export function* fetchDocumentWorkerSaga(args) {
  const { payload: { mailbox } } = args;
  yield put(toAction(FETCH_DOCUMENT));
  try {
    const emailId = yield call(office.getRestifiedItemId, window.Office);
    const aadToken = yield call(getAadToken);
    const { contextList: maybeContexts, ...rest } = yield call(getDocument, emailId, aadToken, mailbox);
    const contexts = maybeContexts.orJust([]);
    const actionPayload = { ...rest, contextList: isReinsuranceContextsList(contexts) ? toMaybe(setIdPerYearAndBusinessAccountId(contexts)) : maybeContexts };
    yield put(toAction(FETCH_DOCUMENT_SUCCESS, actionPayload));
  } catch (err) {
    yield put(toAction(FETCH_DOCUMENT_ERROR, err));
  }
}

export function* fetchDocumentSagaWatchSaga() {
  yield takeLatest(WATCH_FETCH_DOCUMENT, fetchDocumentWorkerSaga);
}
