export const FETCH_ROLES = 'user/FETCH_ROLES';
export const FETCH_ROLES_ERROR = 'user/FETCH_ROLES_ERROR';
export const FETCH_ROLES_SUCCESS = 'user/FETCH_ROLES_SUCCESS';
export const FETCH_USER = 'user/FETCH_USER';
export const FETCH_USER_ERROR = 'user/FETCH_USER_ERROR';
export const FETCH_USER_SUCCESS = 'user/FETCH_USER_SUCCESS';
export const ROLE_CHANGED = 'user/ROLE_CHANGED';

export const USER_WATCH_FETCH_USER = 'containers/user/USER_WATCH_FETCH_USER';
export const USER_WATCH_FETCH_ROLES = 'containers/user/USER_WATCH_FETCH_ROLES';
