import generateYearOptions from './generateYearOptions';

const markSelectedContextOptions = (clickedContext, clickedOptions) => {
  // user clicked on a body of the context => select all
  if (!clickedOptions) return { ...clickedContext, yearOptions: clickedContext.yearOptions ? generateYearOptions(clickedContext.yearOptions.map(x => x.value)) : undefined };

  // user cliecked on some option => select single
  return { ...clickedContext, yearOptions: generateYearOptions(clickedOptions.map(x => x.value)) };
};

const removeContext = (selectedContexts, clickedContext) => selectedContexts.filter(x => x.id !== clickedContext.id);

const addContext = (selectedContexts, clickedContext, clickedOptions) => [...selectedContexts, markSelectedContextOptions(clickedContext, clickedOptions)];

const updateSelectedContexts = (selectedContexts, isSelected, clickedContext, clickedOptions) => {
  // if it is not selected now => select it
  if (!isSelected) return addContext(selectedContexts, clickedContext, clickedOptions);

  // if it is selected now AND
  // if user pressed on a body of the context OR user pressed on single selected option
  if (!clickedOptions || clickedOptions.length === 0) return removeContext(selectedContexts, clickedContext);

  // if it is selected now AND user pressed on one of the selected options
  return selectedContexts.map(ctx => ({
    ...ctx,
    yearOptions: ctx.id === clickedContext.id ? generateYearOptions(clickedOptions.map(x => x.value)) : ctx.yearOptions
  }));
};

export default updateSelectedContexts;
