import { find, curry, pipe } from 'ramda';
import { toMaybe } from '../../../lib';

const maybeFind = curry(
  (fn, list) => pipe(find, toMaybe)(fn, list)
);

const getSelectedDocType = documentTypes =>
  maybeFind(docType => docType.selected && !docType.disabled, documentTypes)
    .orJust('');

export default getSelectedDocType;
