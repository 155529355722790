import { connect } from 'react-redux';
import { User } from '../../components';
import * as a from './actions';
import {
  getAnalysisWatcherAction,
} from '../DocumentPage/actions';
import { resetContextList } from '../ContextList/actions';
import { resetSearch } from '../SearchPage/actions';

const mapStateToProps = (
  { user, archivedPage: { hasArchived } },
) => ({
  ...user,
  isArchived: hasArchived
});

const mergeProps = (sProps, dProps) => ({
  ...sProps,
  ...dProps,
  roleChanged: role => {
    resetContextList(dProps.dispatch)();
    dProps.dispatch(resetSearch);
    a.roleChanged(dProps.dispatch, role);
    dProps.dispatch(
      getAnalysisWatcherAction(
        {
          ...sProps.user.data,
          role,
        },
        window.Office.context.mailbox.item.itemId
      ),
    );
  }
});

export default connect(
  mapStateToProps,
  dispatch => ({ dispatch }),
  mergeProps,
)(User);
