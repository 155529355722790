import { Link } from 'react-router-dom';
import React from 'react';
import { routes } from '../../App/Router';

const NotFoundPage = () => (
  <div>
    <Link to={routes.root}>Back</Link>
    <p>This page does not exist</p>
  </div>
);

export default NotFoundPage;
