import { Just, Nothing } from 'monet';

const remoteDataToMaybe = (val: any) =>
  val.cata({
    NotAsked: () => Nothing(),
    Loading: () => Nothing(),
    Failure: (_: any) => Nothing(),
    Success: (data: any) => Just(data),
  });

export default remoteDataToMaybe;
