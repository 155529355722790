import { Just, Nothing } from 'monet';
import mapTags from './mapTags';

const mapUpdateDocumentTags = updatedTaxonomy => {
  const gotDocumentTags = !!(updatedTaxonomy
    && updatedTaxonomy.taxonomy
    && updatedTaxonomy.taxonomy.documentTags
    && updatedTaxonomy.taxonomy.documentTags.length);

  return gotDocumentTags
    ? Just(mapTags(updatedTaxonomy.taxonomy.documentTags))
    : Nothing();
};

export default mapUpdateDocumentTags;
