import { withRouter } from 'react-router';
import * as a from './actions';
import { resetDocumentPage } from '../DocumentPage/actions';
import { resetContextList } from '../ContextList/actions';
import { resetContextNav } from '../ContextNav/actions';
import { resetSearch } from '../SearchPage/actions';
import { getMailbox, connectWithInit } from '../../lib';
import ArchivedPage from '../../pages/ArchivedPage';

const mapStateToProps = ({ archivedPage, user, documentPage }) =>
  ({
    ...archivedPage,
    userCanUnlock: true,
    mailbox: getMailbox(user.user),
    docPageHasArchived: documentPage.hasArchived,
    archivedPageHasArchived: archivedPage.hasArchived,
  });

const mapDispatchToProps = dispatch => ({
  resetDocumentPage: resetDocumentPage(dispatch),
  resetContextList: resetContextList(dispatch),
  resetContextNav: resetContextNav(dispatch),
  dispatch,
});

// TODO: Probably more clean to use app actions and let appReducer reset it
const init = props => {
  a.setHasArchived(props.dispatch, props.docPageHasArchived);
  props.dispatch(resetSearch);
  props.resetDocumentPage();
  props.resetContextList();
  props.resetContextNav();

  if (props.document.isNotAsked()) {
    props.fetchDocument();
  }

  const hasEditedAndArchived = props.match.params.hasEdited === 'true' && props.document.isSuccess();
  if (hasEditedAndArchived) {
    props.fetchDocument();
  }
};

const mergeProps = (sProps, dProps, ownProps) => ({
  ...sProps,
  ...dProps,
  ...ownProps,
  fetchDocument: () =>
    dProps.dispatch(
      a.fetchDocumentSagaWatchAction(sProps.mailbox),
    ),
});

export default connectWithInit(
  init,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(withRouter(ArchivedPage));
