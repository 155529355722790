import { map, curry, pipe } from 'ramda';
import { sortDesc } from '../../../lib';

const setSelectedDocType = curry(
  (analysisDocType, docTypes) =>
    map(dT => ({
      ...dT, selected: dT.value === analysisDocType
    }), docTypes)
);

const mapSortDocumentTypes = (analysisDocType, documentTypes) =>
  pipe(
    setSelectedDocType(analysisDocType),
    sortDesc('displayName')
  )(documentTypes);

export default mapSortDocumentTypes;
