import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { mdiCheck, mdiClose, mdiDeleteForever } from '@mdi/js';
import { Circle } from 'its-react-ui';
import { ArchiveIcon, ExclamationIcon } from '../../../assets';
import { showCannotUnarchiveNotOwnerModal } from '../../../containers/App/ModalActions';
import { remoteDataShape, validationShape } from '../../../lib';
import { Icon } from '../../../ui';
import styles from './archiveBar/ArchiveBar.module.scss';
import vars from '../../../scss/variables.scss';

const getUpdateAction = props =>
  props.editMode && props.validation.valid
    ? props.updateDocument
    : props.showValidationResult;

const ArchiveButton = ({
  archiveDocument,
  invalidAttachments,
  showValidationResult,
  validation
}) => {
  const [isArchiveDisabled, setIsArchiveDisabled] = useState(false);
  if (isArchiveDisabled && invalidAttachments.length > 0) setIsArchiveDisabled(false);
  const showExclamation = !validation.valid && validation.showValidationResult;
  const onClickHandler = () => {
    if (validation.valid) {
      setIsArchiveDisabled(true);
      archiveDocument();
    } else {
      showValidationResult();
    }
  };

  return (
    <button type="button"
            disabled={isArchiveDisabled}
            className={styles.archiveButton}
            onClick={onClickHandler}>
      <div className={styles.content}>
        <ArchiveIcon />
        <span>Archive</span>
        {isArchiveDisabled && <span className={styles.spinner}><Circle /></span>}
        {showExclamation && <span className={styles.exclamation}><ExclamationIcon /></span>}
      </div>
    </button>
  );
};

ArchiveButton.propTypes = {
  archiveDocument: PropTypes.func.isRequired,
  invalidAttachments: PropTypes.arrayOf(PropTypes.string),
  showValidationResult: PropTypes.func.isRequired,
  validation: PropTypes.shape(validationShape).isRequired
};

const CancelOrUpdate = props => (
  <div className={styles.cancelOrUpdateOrArchive}>
    <div className={styles.buttonRow}>
      <button className={!props.userCanUnArchive ? styles.isDisabled : null}
              type="link"
              onClick={
                props.userCanUnArchive
                  ? props.onLetsUnarchive
                  : () => showCannotUnarchiveNotOwnerModal(
                    props.dispatch,
                    props.originallyArchivedByName,
                  )
              }
      >
        <Icon path={mdiDeleteForever} size={vars.iconSizeMedium} />
        <div>Unarchive</div>
      </button>
      <div />
      <Link to="/archived">
        <button>
          <Icon path={mdiClose} size={vars.iconSizeMedium} />
          <div>Cancel</div>
        </button>
      </Link>
      <div />
      <button type="button"
              disabled={!props.hasArchivedDocument.isNotAsked()}
              onClick={getUpdateAction(props)}>
        <Icon path={mdiCheck} size={vars.iconSizeMedium} />
        <div>Update</div>
      </button>
    </div>
  </div>
);

CancelOrUpdate.propTypes = {
  hasArchivedDocument: PropTypes.shape(remoteDataShape).isRequired,
  onLetsUnarchive: PropTypes.func.isRequired,
  userCanUnArchive: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  originallyArchivedByName: PropTypes.string.isRequired,
};

const getOriginallyArchivedByName = originallyArchivedBy => {
  if (originallyArchivedBy) {
    return originallyArchivedBy.orJust({ user: { name: 'not set' } }).user.name;
  }
  return '';
};
const ArchiveBar = ({ editMode, ...rest }) => {
  const dispatch = useDispatch();
  const originallyArchivedBy = useSelector(state => {
    if (state.archivedPage.document.data) {
      return state.archivedPage.document.data.originallyArchivedBy;
    }
    return undefined;
  });
  const classNames = cn({
    [styles.container]: true,
  });

  return (
    <div className={classNames}>
      {editMode
        ? (
          <CancelOrUpdate {...rest}
                          editMode={editMode}
                          dispatch={dispatch}
                          originallyArchivedByName={
                            getOriginallyArchivedByName(originallyArchivedBy)
                          } />
        )
        : <ArchiveButton {...rest} />}
    </div>
  );
};

ArchiveBar.propTypes = {
  archiveDocument: PropTypes.func.isRequired,
  editMode: PropTypes.bool.isRequired,
  hasArchivedDocument: PropTypes.shape(remoteDataShape).isRequired,
  invalidAttachments: PropTypes.arrayOf(PropTypes.string),
  showValidationResult: PropTypes.func.isRequired,
  userCanUnArchive: PropTypes.bool.isRequired,
  validation: PropTypes.shape(validationShape).isRequired
};

export default ArchiveBar;
