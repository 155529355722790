import { map } from 'ramda';

const mapToTags =
  map(({ displayName }) => ({
    name: displayName,
    displayName
  }));

const mapTaxonomyToDocTypesResp = maybeTaxonomy =>
  maybeTaxonomy.map(({ documentType, documentTags }) => ([{
    displayName: documentType.displayName,
    name: documentType.displayName,
    tags: mapToTags(documentTags)
  }]));

export default mapTaxonomyToDocTypesResp;
