import generateYearOptions from './generateYearOptions';

const generateId = (ctx, year) => ({ ...ctx, insuranceYear: year, id: `${year}-${ctx.businessAccountId}` });

export const setIdPerYearAndBusinessAccountId = contexts => contexts.map(ctx => generateId(ctx, ctx.insuranceYear));

const generateYearsPerBusinessAccountId = contexts => contexts.reduce((acc, ctx) => {
  const years = generateYearOptions().map(x => x.value);
  const newContexts = years.map(y => generateId(ctx, y));
  return [...acc, ...newContexts];
}, []);

// If coversation analysis for ReinsuranceContext returned the values, then InsuranceYear will be set and ID will be unique
const generateYearsForExistingReinsuranceContexts = contexts => Array.from(contexts.reduce((acc, val) => {
  if (acc.has(val.businessAccountId)) {
    const ctx = acc.get(val.businessAccountId);
    const selectedYears = ctx.yearOptions.filter(x => x.selected).map(x => x.value);
    acc.set(val.businessAccountId, { ...ctx, yearOptions: generateYearOptions([...selectedYears, val.insuranceYear]) });
  } else {
    acc.set(val.businessAccountId, {
      ...val, insuranceYear: 0, id: `0-${val.businessAccountId}`, yearOptions: generateYearOptions([val.insuranceYear])
    });
  }
  return acc;
}, new Map()).values());

export const generateUniqueIdsForReinsuranceContexts = (contexts, generateYears, isAnalysis = false) => {
  if (isAnalysis) {
    return contexts[0].insuranceYear !== 0 && contexts[0].id !== contexts[0].businessAccountId
      ? generateYearsForExistingReinsuranceContexts(contexts)
      : setIdPerYearAndBusinessAccountId(contexts);
  }

  return generateYears ? generateYearsPerBusinessAccountId(contexts) : setIdPerYearAndBusinessAccountId(contexts);
};
