import { Just } from 'monet';
import { filter, find, partition } from 'ramda';

const syncContextList = (maybeContextList, maybeSelectedContexts) =>
  maybeContextList.cata(
    () => maybeSelectedContexts,
    contexts => {
      const selectedContexts = maybeSelectedContexts.orJust([]);
      const selectedNotInContextList =
        filter(selectedContext =>
          !find(context => context.id === selectedContext.id, contexts), selectedContexts);
      const [selectedInContextList, notSelectedContexts] =
        partition(context =>
          find(selectedContext => selectedContext.id === context.id, selectedContexts), contexts);
      return Just([...selectedInContextList, ...selectedNotInContextList, ...notSelectedContexts]);
    },
  );

const selectSyncedDocumentAnalysis = (documentAnalysis, selectedContexts) =>
  documentAnalysis.map(analysis => ({
    ...analysis,
    contextList:
      analysis.isGrouped
        ? analysis.contextList.flatMap(c => c.flatMap(d => syncContextList(d, selectedContexts)))
        : syncContextList(analysis.contextList, selectedContexts),
  }));

export default selectSyncedDocumentAnalysis;
