import { debounce } from 'debounce';
import { withRouter } from 'react-router';
import DocumentPage from '../../pages/DocumentPage';
import * as a from './actions';
import * as appActions from '../App/actions';
import {
  fetchSuggestions as apiFetchSuggestions,
} from '../../apis';
import selectSyncedDocumentAnalysis from './selectors';
import validateDocument from './validation';
import { getReceivedAt } from './helpers';
import { getMailbox, connectWithInit } from '../../lib';
import selectUserCanUnlock from './selectUserCanUnarchive';
import { routes } from '../../App/Router';

const debouncedFetchSuggestions = debounce(a.fetchSuggestions, 200);

const updateValidation = ({ validation: { showValidationResult }, documentModel, isPiiChecked }, contexts) =>
  validateDocument({
    ...documentModel,
    contexts,
  }, showValidationResult, { isPiiChecked });

export const getApplicationRoleName = (data, remoteRoles) => {
  const { applicationRoleName } = data
    ? data.content.taxonomy.orJust({ applicationRoleName: undefined })
    : { applicationRoleName: undefined };
  if (applicationRoleName) {
    return remoteRoles.find(r => r.value === applicationRoleName);
  }
  return undefined;
};

const mapStateToProps = ({
  app, documentPage, contextList, user, archivedPage,
}) => ({
  ...documentPage,
  userCanUnArchive: selectUserCanUnlock(archivedPage.document, user.user),
  validation: updateValidation(documentPage, contextList.selectedContexts.orJust([])),
  documentAnalysis:
    selectSyncedDocumentAnalysis(documentPage.documentAnalysis, contextList.selectedContexts),
  receivedAt: getReceivedAt(documentPage.documentAnalysis),
  editMode: app.editMode,
  user: {
    role: user.activeRole,
    mailbox: getMailbox(user.user),
    userMailbox: getMailbox(user.user, 'userMailbox'),
    remoteRoles: user.remoteRoles,
  },
  archivedDocument: archivedPage.document,
  showDocumentExist: false,
  applicationRoleName: getApplicationRoleName(archivedPage.document.data, user.remoteRoles.data),
  invalidAttachments: documentPage.invalidAttachments,
  selectedDocType: documentPage.documentModel.documentType,
});

const mapDispatchToProps = dispatch => ({
  archiveDocument: args => {
    dispatch(a.validateAttachmentsSagaWatchAction({ ...args }));
  },
  classificationsChanged: a.classificationsChanged(dispatch),
  deleteKeywordClicked: a.deleteKeyword(dispatch),
  toggleAddKeywords: g => a.toggleAddKeywords(dispatch, g === true),
  searchStringChanged: a.searchStringChanged(dispatch),
  showValidationResult: a.showValidationResult(dispatch),
  addKeyword: a.addKeyword(dispatch),
  toggleContextListing: a.toggleContextListing(dispatch),
  dispatch,
});

const mergeProps = (sProps, dProps, routerProps) => ({
  ...sProps,
  ...dProps,
  ...routerProps,
  currentMessageId: window.Office.context.mailbox.item.itemId,
  noteChanged: note => {
    a.addNote(dProps.dispatch, note);
  },
  updateNoteKeywords: note => {
    debouncedFetchSuggestions(
      dProps.dispatch,
      apiFetchSuggestions,
      sProps.applicationRoleName || sProps.user.role,
      sProps.selectedDocType,
      note,
    );
  },
  isEdit: routerProps.location.pathname === routes.edit,
  updateDocument: args => {
    dProps.dispatch(
      a.updateDocumentSagaWatchAction({
        ...args,
        applicationRoleName: sProps.applicationRoleName,
        mailBox: sProps.user.mailbox,
      }),
    );
  },
  getArchivedDocumentRole: data =>
    getApplicationRoleName(data, sProps.user.remoteRoles.data),
  fetchEditDocument: archivedDocument => {
    const applicationRole =
      getApplicationRoleName(archivedDocument.data, sProps.user.remoteRoles.data);
    return dProps.dispatch(
      a.fetchEditDocumentWatchAction(
        sProps.user.role,
        archivedDocument,
        applicationRole,
        window.Office.context.mailbox.item.itemId,
      ),
    );
  },
  fetchAnalysis: () =>
    dProps.dispatch(
      a.getAnalysisWatcherAction(sProps.user, window.Office.context.mailbox.item.itemId),
    ),
  documentTypesChanged: documentType =>
    dProps.dispatch(a.updateTaxonomyWatchAction(sProps.user, documentType)),
});

const init = props => {
  const { location, dispatch } = props;
  const isEditing = location.pathname === routes.edit;
  appActions.setDocumentMode(dispatch, isEditing);
};

export default connectWithInit(
  init,
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(withRouter(DocumentPage));
