import {
  sortWith, prop, descend,
} from 'ramda';
import mapMaybeValidatedContexts from './mapMaybeValidatedContexts';

const sortByYearAndClaimNum =
  sortWith([
    descend(prop('insuranceYear')),
    descend(prop('claimNumber'))
  ]);

const mapSearchResult = validatedContexts =>
  mapMaybeValidatedContexts({ validatedContexts })
    .map(sortByYearAndClaimNum);

export default mapSearchResult;
