import { connect } from 'react-redux';
import { prop } from 'ramda';
import { withRouter } from 'react-router';
import UnarchivePage from '../../components/UnarchivePage/UnarchivePage';
import { resetApp } from '../App/actions';
import {
  unarchiveDocument,
  cancelAfterFailure,
} from './actions';
import { getMailbox, remoteDataToMaybe } from '../../lib';

const getDocumentId = remoteDocument => remoteDataToMaybe(remoteDocument)
  .map(prop('id'))
  .orJust(null);

const mapStateToProps = (
  { user, unarchivePage: { unArchiveResult }, archivedPage: { document } },
) => ({
  documentId: getDocumentId(document),
  role: user.activeRole,
  unArchiveResult,
  user,
});

const mapDispatchToProps = dispatch => ({
  cancelAfterFailure: () => cancelAfterFailure(dispatch),
  resetApp: () => dispatch(resetApp),
  dispatch,
});

const mergeProps = (sProps, dProps, ownProps) => ({
  ...sProps,
  ...dProps,
  ...ownProps,
  unArchiveDocument: args => {
    const mailBox = getMailbox(sProps.user.user);
    return unarchiveDocument({
      ...args,
      dispatch: dProps.dispatch,
      mailBox,
    });
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps,
)(withRouter(UnarchivePage));
