import React from 'react';
import PropTypes from 'prop-types';
import styles from './PageTitle.module.scss';
import { DocIcon, DocArchivedIcon } from '../../assets';

const getIcon = icon =>
  icon === 'doc'
    ? <DocIcon />
    : <DocArchivedIcon />;

const PageTitle = ({ icon, title }) => (
  <div className={styles.titleContainer}>
    {getIcon(icon)}
    <h1 className={styles.title}>{title}</h1>
  </div>
);

PageTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default PageTitle;
