import generateYearOptions from './generateYearOptions';

const initialContextsSelection = (contexts, selectedContexts, isMultiSelect) => {
  const markedSelectedOptions = selectedContext => selectedContext ? selectedContext.yearOptions : generateYearOptions();
  return contexts.map(context => {
    const selectedContext = selectedContexts.find(c => c.id === context.id);
    return { ...context, selected: !!selectedContext, yearOptions: isMultiSelect ? markedSelectedOptions(selectedContext) : undefined };
  });
};

export default initialContextsSelection;
