const alreadyRestId = hostName =>
  hostName === 'OutlookIOS';

const convertToRestId = (mailbox, restVersion) =>
  mailbox && mailbox.convertToRestId
    ? mailbox.convertToRestId(mailbox.item.itemId, restVersion)
    : mailbox.item.itemId;

export const getRestifiedItemId = ({ context: { mailbox }, MailboxEnums }) =>
  alreadyRestId(mailbox.diagnostics.hostName)
    ? mailbox.item.itemId
    : convertToRestId(mailbox, MailboxEnums.RestVersion.v2_0);

export const inSharedMailBox = Office =>
  (Office.context.requirements.isSetSupported('MailBox', 1.7) &&
    typeof Office.context.mailbox.item.getSharedPropertiesAsync !== 'undefined');

export const getSharedMailBoxOwner = Office =>
  // eslint-disable-next-line no-promise-executor-return
  new Promise((resolve, reject) => Office.context.mailbox.item.getSharedPropertiesAsync(result => {
    if (result.status === Office.AsyncResultStatus.Succeeded) {
      return resolve(result.value.owner);
    }

    return reject(new Error(`Could not get shared owner: ${result.status}`));
  }));

/*
REMARK:
The string value in insifyCategoryName (Archived in Insify Document) must correspond with value of
private const string InsifyCategoryName = "Archived in Insify Document";
in \its-utility\Its.Utility.GraphApiService\EmailService\GraphApiEmailService.cs
 */
const insifyCategoryNames = ['Archived in Insify Document'];

export const hasInsifyCategory = mailItem => { // eslint-disable-line
  // const item = mailItem || window.Office.context.mailbox.item;
  return new Promise(resolve => {
    /*
      REMARK: This method now always resolves true.
      The reason is temporarily disabling categories <LEAPDEV-1180>
    */
    resolve(true);
    /* try {
      item.categories.getAsync(asyncResult => {
        if (asyncResult.status === window.Office.AsyncResultStatus.Succeeded) {
          if (asyncResult && asyncResult.value && asyncResult.value.length > 0) {
            const filtered = asyncResult.value.filter(c => c.displayName === 'Archived in Insify Document');
            resolve(filtered.length > 0);
          } else {
            resolve(false);
          }
        }
        resolve(false);
      });
    } catch (e) {
      resolve(false);
    } */
  });
};

export const addItemCategoryToItem = mailItem => { // eslint-disable-line
  // const item = mailItem || window.Office.context.mailbox.item;
  return new Promise((resolve, reject) => {
    try {
      /*
        REMARK: This method now always resolves { result: true }.
        The reason is temporarily disabling categories <LEAPDEV-1180>
      */
      resolve({ result: true });
      /* item.categories.addAsync(
        insifyCategoryNames,
        asyncResult =>
          asyncResult.status === window.Office.AsyncResultStatus.Succeeded
            ? resolve({ result: true })
            : reject(new Error(`The predefined category "${insifyCategoryNames}" is missing. +
            Please contact support if this message persists.`)),
      ); */
    } catch (e) {
      reject(new Error(`Cannot set category ${insifyCategoryNames} on archived document. +
                        Your e-mail client may not have been updated or the internal access rights are not set correct. +
                        Please contact support if this message persists.`));
    }
  });
};

export const removeItemCategoryFromItem = mailItem => { // eslint-disable-line
  // const item = mailItem || window.Office.context.mailbox.item;
  return new Promise((resolve, reject) => {
    try {
      /*
        REMARK: This method now always resolves { result: true }.
        The reason is temporarily disabling categories <LEAPDEV-1180>
      */
      resolve({ result: true });
      /* item.categories.removeAsync(
        insifyCategoryNames,
        asyncResult =>
          asyncResult.status === window.Office.AsyncResultStatus.Succeeded
            ? resolve({ result: true })
            : reject(new Error(`The predefined category "${insifyCategoryNames}" could not be removed while unarchiving.+
                                Please contact support if this message persists.`)),
      ); */
    } catch (e) {
      reject(new Error(`Cannot remove category ${insifyCategoryNames} on unarchived document. +
              Your e-mail client may not have been updated or the internal access rights are not set correct. +
              Please contact support if this message persists.`));
    }
  });
};
