import { curry } from 'ramda';
import * as actions from './constants';
import { toAction, dispatchFetchError, dispatchFetchSuccess } from '../../lib';

export const fetchSuggestions = (dispatch, apiFetchSuggestions, role, selectedDocType, note) =>
  apiFetchSuggestions(role.value, selectedDocType, note)
    .then(dispatchFetchSuccess(dispatch, actions.FETCH_SUGGESTIONS_SUCCESS))
    .catch(dispatchFetchError(dispatch, actions.FETCH_SUGGESTIONS_ERROR));

export const classificationsChanged = curry(
  (dispatch, selected) =>
    dispatch(toAction(actions.CLASSIFICATIONS_CHANGED, selected)),
);

export const deleteKeyword = curry(
  (dispatch, tag) =>
    dispatch(toAction(actions.DELETE_KEYWORD, tag)),
);

export const toggleAddKeywords = (dispatch, forceOpen) => {
  dispatch(toAction(actions.TOGGLE_ADD_KEYWORDS, forceOpen));
};

export const searchStringChanged = curry(
  (dispatch, searchString) =>
    dispatch(toAction(actions.SEARCH_STRING_CHANGED, searchString)),
);

export const addKeyword = curry(
  (dispatch, tag) =>
    dispatch(toAction(actions.ADD_KEYWORD, tag)),
);

export const showValidationResult = dispatch =>
  () => dispatch(toAction(actions.SHOW_VALIDATION_RESULT));

export const resetDocumentPage = dispatch =>
  () => dispatch(toAction(actions.RESET_DOCUMENT_PAGE));

export const toggleContextListing = dispatch =>
  () => dispatch(toAction(actions.TOGGLE_CONTEXT_LISTING));

export const addNote = (dispatch, note) =>
  dispatch(toAction(actions.ADD_NOTE, note));

export const fetchEditDocumentWatchAction = (role, archivedDocument, applicationRoleName, messageId) =>
  toAction(actions.WATCH_FETCH_EDIT_DOCUMENT, {
    role,
    archivedDocument,
    applicationRoleName,
    messageId,
  });

export const getAnalysisWatcherAction = (user, messageId) =>
  toAction(actions.WATCH_ANALYSE_DOCUMENT, { user, messageId });

export const validateAttachmentsSagaWatchAction = args =>
  dispatch => dispatch(toAction(actions.WATCH_VALIDATE_ATTACHMENTS, args));

export const updateDocumentSagaWatchAction = args =>
  dispatch => dispatch(toAction(actions.WATCH_UPDATE_DOCUMENT, args));

export const updateTaxonomyWatchAction = (user, documentType) =>
  toAction(actions.WATCH_UPDATE_TAXONOMY, { user, documentType });
