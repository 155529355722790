import PropTypes from 'prop-types';
import React from 'react';
import { SadFaceIcon } from '../../../assets';
import styles from './Unarchive.module.scss';

const Failure = ({ onClose }) => (
  <>
    <SadFaceIcon className={styles.icon} />
    <h4 className="bold">That didn’t go well</h4>
    <h4>Contact support or try again later.</h4>
    <div className={styles.buttonFlex}>
      <button className={styles.close}
              type="button"
              onClick={onClose}>
        <span>Close</span>
      </button>
    </div>
  </>
);

Failure.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default Failure;
