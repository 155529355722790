import React, { useEffect } from 'react';
import { Provider } from 'react-redux';
import { MsalProvider } from '@azure/msal-react';
import configureStore from './store/configureStore';
import { resetApp } from './containers/App/actions';
import App from './App/App';
import { NotEmailPage } from './components';
import { FeatureFlagProvider } from './providers/FeatureFlagProvider';
import createPublicClientApplication from './appAuth/msalAuth';

const msalInstance = createPublicClientApplication();

const store = configureStore({});

interface BootstrapAddinProps {
  resetStore: boolean;
  isItemEmail: boolean;
}
const BootstrapAddin = ({ resetStore, isItemEmail }: BootstrapAddinProps) => {
  const [readyToRender, setReadyToRender] = React.useState(false);
  useEffect(() => {
    if (resetStore) {
      store.dispatch(resetApp);
    }
    setReadyToRender(true);
  }, [resetStore]);

  // Need to ensure that the store is reset before rendering the app
  if (!readyToRender) {
    return null;
  }

  return isItemEmail
    ? (
      <MsalProvider instance={msalInstance}>
        <Provider store={store}>
          <FeatureFlagProvider>
            <App />
          </FeatureFlagProvider>
        </Provider>
      </MsalProvider>
    )
    : <NotEmailPage />;
};

export default BootstrapAddin;
