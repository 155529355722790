import { handleActions } from 'redux-actions';
import * as a from './constants';

const defaultState = {
  data: {},
};

const reducer = handleActions({
  [a.COVER_INFO_GET]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: {
        isLoading: true,
        error: undefined,
      },
    },
  }),
  [a.COVER_INFO_GET_SUCCESS]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: {
        ...action.payload,
        isLoading: false,
        error: undefined,
      },
    },
  }),
  [a.COVER_INFO_GET_FAILED]: (state, action) => ({
    ...state,
    data: {
      ...state.data,
      [action.payload.id]: {
        isLoading: false,
        error: action.payload.err,
      },
    },
  }),
}, defaultState);

export default reducer;
