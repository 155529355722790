import { map, curry } from 'ramda';

const updateSelected = curry(
  (selectedItem, list) =>
    map(item => ({
      ...item, selected: item.value === selectedItem.value
    }), list)
);

export default updateSelected;
