import useSWR from 'swr';
import { get } from '../../apis/base';
import { addFavorite, removeFavorite } from '../../apis';

// eslint-disable-next-line import/prefer-default-export
export const useFavorites = () => {
  const { data, mutate } = useSWR('/api/user/favorites', get);
  const add = documentType => {
    addFavorite(documentType);
    mutate([...data, documentType], false);
  };
  const remove = documentType => {
    removeFavorite(documentType);
    mutate(data.filter(removed => removed !== documentType), false);
  };
  return [data, add, remove];
};
